import { visuallyHidden } from "@mui/utils";
import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";

type ListHeadProps = {
  order?: "ascending" | "descending";
  orderBy?: string;
  headLabel: any[];
  onRequestSort?: (id: string) => void;
};

export default function ListHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}: ListHeadProps) {
  const getOrderValue = (orderValue?: "ascending" | "descending") => {
    if (orderValue === "ascending") {
      return "asc";
    } else {
      return "desc";
    }
  };
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
            sortDirection={
              orderBy === headCell.id ? getOrderValue(order) : false
            }
          >
            {headCell?.sort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? getOrderValue(order) : "asc"
                }
                onClick={() => onRequestSort && onRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "descending"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
