import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

interface ConfirmationDialogProps {
  handleClose: VoidFunction;
  handleSubmit: VoidFunction;
  openDialog: boolean;
  title: string;
  isSubmitting: boolean;
}
export default function ConfirmationDialog({
  handleClose,
  handleSubmit,
  openDialog,
  title,
  isSubmitting,
}: ConfirmationDialogProps) {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          color="primary"
        >
          Yes
        </LoadingButton>
        <Button onClick={handleClose} autoFocus color="error">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
