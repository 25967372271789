import { Stack, Checkbox, FormGroup, Typography, FormControlLabel } from '@mui/material';
import Scrollbar from '../../../Scrollbar';
import { CUSTOMER_SERVICE_RECORD_TYPE_FILTER } from 'utils/constants/filters';
import { FormikPropsCustomerServiceSearchView } from '../../../../@types/customerServiceSearch';

type CustomerServiceSearchFiltersSidebarProps = {
  formik: FormikPropsCustomerServiceSearchView;
};

export default function CustomerServiceSearchFiltersSidebar({
  formik,
}: CustomerServiceSearchFiltersSidebarProps) {
  const { values, getFieldProps } = formik;

  return (
    <Scrollbar>
      <Stack spacing={3} sx={{ p: 3 }}>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Status
          </Typography>
          <FormGroup>
            {CUSTOMER_SERVICE_RECORD_TYPE_FILTER.map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    {...getFieldProps('recordType')}
                    value={item}
                    checked={values.recordType.includes(item)}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>
      </Stack>
    </Scrollbar>
  );
}
