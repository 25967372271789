import { CircularProgress } from "@mui/material";
import { ReactNode } from "react";
import EmptyTable from "../EmptyTable";
import FilterNotFound from "./FilterNotFound";
import NoResultsFound from "./NoResultsFound";
import SearchNotFound from "./SearchNotFound";

interface TableWrapperProps {
  loading: boolean;
  listLength: boolean;
  isDefaultFilter: boolean;
  searchQuery?: string;
  children: ReactNode;
  colSpan: number;
}

export default function TableWrapper({
  loading,
  listLength,
  isDefaultFilter,
  searchQuery,
  children,
  colSpan,
}: TableWrapperProps) {
  return (
    <>
      {loading ? (
        <EmptyTable colSpan={colSpan}>
          <CircularProgress />
        </EmptyTable>
      ) : (
        children
      )}
      {!listLength && !loading && (
        <EmptyTable colSpan={colSpan}>
          {searchQuery && <SearchNotFound searchQuery={searchQuery} />}
          {!searchQuery && !isDefaultFilter && <FilterNotFound />}
          {!searchQuery && isDefaultFilter && <NoResultsFound />}
        </EmptyTable>
      )}
    </>
  );
}
