import React, { FC, ReactElement } from 'react';
import {
  Stack,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Tooltip,
  Grid,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { MAINTENANCE_FILTERS } from 'utils/constants/filters';
import Scrollbar from 'components/Scrollbar';
import { FormikPropsMaintenanceView } from '../../../../@types/maintenance';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Box from '@mui/material/Box';

interface MaintenanceEventsSidebarProps {
  formik: FormikPropsMaintenanceView;
  marketplacesData: { name: string; value: string }[];
}

const MaintenanceEventsSidebar: FC<MaintenanceEventsSidebarProps> = ({
  formik,
  marketplacesData,
}): ReactElement => {
  const { values, getFieldProps, setFieldValue } = formik;

  const filtersHandler = MAINTENANCE_FILTERS.map((filter) => {
    if (filter.name === 'marketplaces') {
      return { ...filter, values: marketplacesData };
    }
    return filter;
  });
  return (
    <Scrollbar>
      <Stack spacing={3} sx={{ p: 3 }}>
        {filtersHandler.map((filter) => (
          <div key={filter.name}>
            {filter.name === 'maintenanceTaskStatus' && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  {filter.label}
                </Typography>
                <FormGroup>
                  {filter.values.map((value) => {
                    let filterValue = value as {
                      value: string;
                      name: string;
                    };
                    return (
                      <FormControlLabel
                        key={filterValue.value}
                        control={
                          <Checkbox
                            {...getFieldProps(filter.name)}
                            value={filterValue.value}
                            checked={values.maintenanceTaskStatus.includes(filterValue.value)}
                          />
                        }
                        label={value.name}
                      />
                    );
                  })}
                </FormGroup>
              </>
            )}{' '}
            {(filter.name === 'startDate' || filter.name === 'endDate') && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  {filter.label}
                </Typography>

                <Box mb="8px">
                  <MobileDateTimePicker
                    label="From"
                    value={values[filter.name][0].from}
                    inputFormat='DD/MM/YYYY hh:mm a'
                    onChange={(date: Date | null) => setFieldValue(`${filter.name}[0].from`, date)}
                    maxDateTime={
                      values[filter.name][0].to ? new Date(values[filter.name][0].to) : undefined
                    }
                    renderInput={(params: TextFieldProps) => <TextField {...params} error={false} fullWidth />}
                  />
                </Box>
                <MobileDateTimePicker
                  label="To"
                  value={values[filter.name][0].to}
                  inputFormat='DD/MM/YYYY hh:mm a'
                  onChange={(date: Date | null) => setFieldValue(`${filter.name}[0].to`, date)}
                  minDateTime={
                    values[filter.name][0].from ? new Date(values[filter.name][0].from) : undefined
                  }
                  renderInput={(params: TextFieldProps) => <TextField {...params} error={false} fullWidth />}
                />
              </>
            )}
            {(filter.name === 'games' || filter.name === 'marketplaces') && (
              <>
                <Grid container alignItems={'center'} mb={'18px'}>
                  <Typography variant="subtitle1">{filter.label}</Typography>
                  <Tooltip
                    title={
                      <Typography>You can select multiple values from the dropdown</Typography>
                    }
                    sx={{ marginLeft: '10px' }}
                  >
                    <InfoIcon fontSize={'small'} />
                  </Tooltip>
                </Grid>
                <FormControl sx={{ width: 200 }}>
                  <InputLabel id="select-label" sx={{ display: 'flex', alignItems: 'center' }}>
                    {filter.label}
                  </InputLabel>

                  <Select
                    multiple
                    displayEmpty
                    label={filter.label}
                    {...getFieldProps(`${filter.name}`)}
                  >
                    {filter.values?.map((data: { value: string; name: string }) => {
                      return (
                        <MenuItem key={data.value} value={data.value}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )}
          </div>
        ))}
      </Stack>
    </Scrollbar>
  );
};

export default MaintenanceEventsSidebar;
