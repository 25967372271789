import {
  CardReaderType,
  ICardReader,
  ICardReaderDeviceInterface,
  OnInitialize,
  OnShutdown,
} from '../../../../@types/cardReader';

export class OpticonCardReader implements ICardReader {
  constructor(deviceInterface: ICardReaderDeviceInterface) {
    this.deviceInterface = deviceInterface;
  }

  deviceInterface: ICardReaderDeviceInterface;

  cardReaderType = CardReaderType.OPTICON_HID;
  needsConfiguration = async () => Promise.resolve(false);
  configure = async () => Promise.resolve(null);
  initialize = async (onInitialize: OnInitialize) => {
    await this.deviceInterface?.initialize();
    onInitialize();
  };
  shutDown = async (isClosing: boolean, onShutdown: OnShutdown) => {
    await this.deviceInterface?.shutDown(isClosing);
    if (!isClosing) onShutdown();
  };
}
