import { TableRow, TableBody, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import {
  CUSTOMER_SERVICE_RECORD_TYPE,
  CompetitionRecordDetails,
  CustomerServiceSearchResult,
  RecordDetails,
} from '../../../@types/customerServiceSearch';

const useStyles = makeStyles(() => ({
  clickable: {
    cursor: 'pointer',
  },
}));

interface CustomerServiceSearchResultsTableProps {
  filteredCustomerServiceSearchResults: CustomerServiceSearchResult[];
}

export default function CustomerServiceSearchResultsTable({
  filteredCustomerServiceSearchResults,
}: CustomerServiceSearchResultsTableProps) {
  const classes = useStyles();
  const navigate = useNavigate();

  const goToDetailPage = async (recordType: string, recordId: string, details: RecordDetails) => {
    if (!recordId || !recordType || !details) return;
    switch (recordType) {
      case CUSTOMER_SERVICE_RECORD_TYPE.COMPETITION:
        const { groupId } = details as CompetitionRecordDetails;
        navigate(`/dashboard/groups/${groupId}/competitions/${recordId}`);
        break;
    }
  };

  return (
    <TableBody>
      {filteredCustomerServiceSearchResults?.map((record) => {
        return (
          <TableRow
            hover
            key={record?.recordId}
            tabIndex={-1}
            className={classes.clickable}
            onClick={() => goToDetailPage(record?.recordType, record?.recordId, record?.details)}
          >
            <TableCell align="left">{record?.recordType || '-'}</TableCell>
            <TableCell align="left">{record?.recordId || '-'}</TableCell>
            <TableCell align="left" sx={{ minWidth: '140px' }}>
              {record?.summary || '-'}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
