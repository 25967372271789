import { filter } from "lodash";
import { Chip, Stack } from "@mui/material";
import {
  CompetitionFilter,
  FormikPropsCompetitionView,
} from "../../../../@types/competition";
import { LabelStyle, WrapperStyle } from "../styles/styles";

type CompetitionFiltersOverviewProps = {
  formik: FormikPropsCompetitionView;
  filters: CompetitionFilter;
};

export default function CompetitionFiltersOverview({
  formik,
  filters,
}: CompetitionFiltersOverviewProps) {
  const { handleSubmit, setFieldValue } = formik;
  const { status, competitionSearchType, competitionType } = filters;

  const handleRemoveStatus = (value: string) => {
    const newValue = filter(status, (_item) => _item !== value);
    handleSubmit();
    setFieldValue("status", newValue);
  };

  const handleRemoveType = (value: string) => {
    const newValue = filter(competitionSearchType, (_item) => _item !== value);
    handleSubmit();
    setFieldValue("competitionSearchType", newValue);
  };

  const handleRemoveContestType = (value: string) => {
    const newValue = filter(competitionType, (_item) => _item !== value);
    handleSubmit();
    setFieldValue("competitionSearchType", newValue);
  };

  return (
    <>
      {!!status.length && (
        <WrapperStyle>
          <LabelStyle>status:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {status.map((_status) => (
              <Chip
                key={_status}
                label={_status}
                size="small"
                onDelete={() => handleRemoveStatus(_status)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
      {!!competitionSearchType.length && (
        <WrapperStyle>
          <LabelStyle>type:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {competitionSearchType.map((type) => (
              <Chip
                key={type}
                label={type}
                size="small"
                onDelete={() => handleRemoveType(type)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
      {!!competitionType.length && (
        <WrapperStyle>
          <LabelStyle>contest type:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {competitionType.map((type) => (
              <Chip
                key={type}
                label={type}
                size="small"
                onDelete={() => handleRemoveContestType(type)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
    </>
  );
}
