export const GROUP_TABLE_HEAD = [
  { id: 'game', label: 'Game', alignRight: false, sort: true },
  { id: 'name', label: 'Group Name', alignRight: false, sort: true },
  {
    id: 'competitionCount',
    label: 'Num Of Competitions',
    alignRight: false,
    sort: true,
  },
  { id: 'status', label: 'Status', alignRight: false, sort: true },
];

export const COMPETITION_TABLE_HEAD = [
  { id: 'game', label: 'Game', alignRight: false, sort: true },
  { id: 'name', label: 'Name', alignRight: false, sort: true },
  { id: 'competitionSearchType', label: 'Type', alignRight: false, sort: true },
  { id: 'startTime', label: 'Start Date', alignRight: false, sort: true },
  {
    id: 'duration',
    label: 'Duration',
    alignRight: false,
    sort: true,
  },
  { id: 'status', label: 'Status', alignRight: false, sort: true },
  { id: '' },
];

export const CUSTOMER_SERVICE_SEARCH_TABLE_HEAD = [
  { id: 'recordType', label: 'Type', alignRight: false, sort: true },
  { id: 'recordId', label: 'ID', alignRight: false, sort: true },
  { id: 'summary', label: 'Summary', alignRight: false, sort: true },
];

export const PARTICIPANTS_TABLE_HEAD = [
  { id: 'rank', label: 'Rank', alignRight: false },
  { id: 'avatar', label: 'Avatar', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'country', label: 'Country', alignRight: false },
  { id: 'percentGain', label: 'Percent Gain', alignRight: false },
  { id: 'numOfPlays', label: 'Num of Plays Made', alignRight: false },
  { id: 'pointsBalance', label: 'Points Balance', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

export const getCompetitionListTabs = (showMultiLevel: boolean) => {
  return showMultiLevel
    ? [
        { value: '1', label: 'Single Level', disabled: false },
        { value: '2', label: 'Multi Level', disabled: false },
      ]
    : [{ value: '1', label: 'Single Level', disabled: false }];
};

export const PARTICIPANTS_PLAYS_HEAD = [
  { id: 'hand', label: 'Hand', alignRight: false },
  { id: 'tpInfo', label: 'Tp Info', alignRight: false },
  { id: 'cards', label: 'Cards', alignRight: false },
  { id: 'play', label: 'Play', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'stake', label: 'Stake', alignRight: false },
  { id: 'points', label: 'Points', alignRight: false },
];

export const LEADERBOARD_TABLE_HEAD = [
  { id: 'rank', label: 'Rank', alignRight: false, sort: true },
  { id: 'avatar', label: 'Avatar', alignRight: false },
  { id: 'playerName', label: 'Player Name', alignRight: false, sort: true },
  { id: 'totalStake', label: 'Total Stake', alignRight: false, sort: true },
  {
    id: 'totalWonLost',
    label: 'Total Won/Lost',
    alignRight: false,
    sort: true,
  },
  { id: 'percentGain', label: 'Percent Gain', alignRight: false, sort: true },
  { id: 'played', label: 'Played', alignRight: false, sort: true },
  {
    id: 'remainingToQualify',
    label: 'Remaining To Qualify',
    alignRight: false,
    sort: true,
  },
  {
    id: 'qualificationStatus',
    label: 'Qualification Status',
    alignRight: false,
    sort: true,
  },
  { id: 'wonAmount', label: 'Won Amount', alignRight: false, sort: true },
  { id: 'wonCurrency', label: 'Won Currency', alignRight: false, sort: true },
];

export const getTemplateListTabs = (showMultiLevel: boolean) => {
  return showMultiLevel
    ? [
        { value: '1', label: 'Single Level', disabled: false },
        { value: '2', label: 'Multi Level', disabled: false },
      ]
    : [{ value: '1', label: 'Single Level', disabled: false }];
};

export const TEMPLATE_SINGLE_LEVEL_TABLE_HEAD = [
  { id: 'game', label: 'Game', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'payoutModel', label: 'Payout Model', alignRight: false },
  { id: 'fee', label: 'Entry Fee', alignRight: false },
  { id: 'minPlaysToQualify', label: 'To Qualify', alignRight: false },
  { id: '' },
];

export const TEMPLATE_MULTI_LEVEL_TABLE_HEAD = [
  { id: 'game', label: 'Game', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'levels', label: 'Levels', alignRight: false },
  { id: '' },
];

export const MARKETPLACE_TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false, sort: true },
  {
    id: 'marketplaceStatus',
    label: 'Status',
    alignRight: false,
    sort: true,
  },
  { id: '' },
];

export const MAINTENANCE_TABLE_HEAD = [
  {
    id: 'start',
    label: 'Start Date',
    alignRight: false,
    sort: true,
  },
  {
    id: 'end',
    label: 'End Date',
    alignRight: false,
    sort: true,
  },
  {
    id: 'marketplaces',
    label: 'B2B Partner(s)',
    alignRight: false,
    sort: true,
  },
  {
    id: 'games',
    label: 'Games',
    alignRight: false,
    sort: true,
  },
  {
    id: 'maintenanceTaskStatus',
    label: 'Status',
    alignRight: false,
    sort: true,
  },
  { id: '' },
];

export const SCHEDULES_TABLE_HEAD = [
  {
    id: 'id',
    label: 'ID',
    alignRight: false,
    sort: false,
  },
  {
    id: 'game',
    label: 'Game',
    alignRight: false,
    sort: false,
  },
  {
    id: 'name',
    label: 'Name',
    alignRight: false,
    sort: false,
  },
  {
    id: 'duration',
    label: 'Duration',
    alignRight: false,
    sort: false,
  },
  {
    id: 'eventType',
    label: 'Event Type',
    alignRight: false,
    sort: false,
  },
];
