import { Paper, Typography } from "@mui/material";

export default function FilterNotFound({ ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not Found
      </Typography>
      <Typography variant="body2" align="center">
        No results found for chosen filter
      </Typography>
    </Paper>
  );
}
