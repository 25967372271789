import { CircularProgress } from "@mui/material";
import React, { ReactElement } from "react";

interface Props {
  children: React.ReactChild;
  url: string;
}

export default function ScriptProvider({ children, url }: Props): ReactElement {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    const element = document.createElement("script");

    element.src = url;
    element.type = "text/javascript";
    element.async = true;

    setReady(false);

    element.onload = () => {
      setReady(true);
    };

    element.onerror = () => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        setReady(true);
      }
    };

    document.head.appendChild(element);

    return () => {
      document.head.removeChild(element);
    };
  }, [url]);
  return (
    <>
      {!ready && (
        <CircularProgress
          color="secondary"
          style={{
            position: "absolute",
            top: "calc(50% - 40px)",
            left: "calc(50% - 40px)",
          }}
        />
      )}
      {ready && children}
    </>
  );
}
