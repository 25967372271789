const time = 15 * 60 * 1000;

let inactivityTimer: NodeJS.Timeout;

export const handleInactivity = (disabled = false) => {
  window.clearTimeout(inactivityTimer);
  if (!disabled) {
    inactivityTimer = setTimeout(() => {
      const inactivityExceptionEvent = new Event('inactivityException');
      document.dispatchEvent(inactivityExceptionEvent);
    }, time);
  }
};
