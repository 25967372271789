import {
  CardReaderType,
  ICardReader,
  InitializeMethod,
  ShutDownMethod,
} from '../../../../@types/cardReader';

export class UndefinedCardReader implements ICardReader {
  initialize: InitializeMethod = async () => {};
  shutDown: ShutDownMethod = async () => {};
  cardReaderType = CardReaderType.NONE;
  needsConfiguration = async () => Promise.resolve(false);
  configure = async () => Promise.resolve(null);
}
