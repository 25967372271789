export const GAME_SIGNALS = {
  START_GAME: 'START_GAME',
  NEW_CARD: 'NEW_CARD',
  START_BETTING_ROUND: 'START_BETTING_ROUND',
  CARDS_REVEAL: 'CARDS_REVEAL',
  START_HAND: 'START_HAND',
};

export const STUDIO_STATES = {
  INITIAL_STATE: 'init-state',
  BANKER_DETERMINATION: 'banker-determination',
  READY_FOR_INTRO: 'ready-for-intro',
  WAIT_FOR_INTRO: 'wait-for-intro',
  READY_TO_DEAL: 'dealing-started',
  READY_FOR_SQUEEZE: 'ready-for-squeeze',
  WAIT_FOR_SQUEEZE: 'wait-for-squeeze',
  WAIT_FOR_READY_FOR_REVEAL: 'wait-for-ready-for-card-reveal',
  READY_FOR_REVEAL: 'dealing-exposed',
  REVEALING_CARDS: 'revealing-cards',
  WAIT_FOR_BET_ROUND_READY: 'wait-for-bet-round-ready',
  READY_FOR_BET_ROUND: 'ready-for-bet-round',
  BETTING_ROUND: 'bet-round',
  WAIT_FOR_HAND_START: 'wait-for-hand-start',
  WAIT_FOR_HAND_START_READY: 'wait-for-hand-start-ready',
  READY_FOR_HAND_START: 'hand-started',
  READY_FOR_OUTRO: 'ready-for-outro',
  WAIT_FOR_OUTRO: 'wait-for-outro',
  EVENT_FINISHED: 'event-finished',
};

export const GSS_SIGNALS = {
  GET_VCS_STATE: 'GET_VCS_STATE',
  SET_EVENT_STARTED: 'SET_EVENT_STARTED',
  SET_NEW_SHOE: 'SET_NEW_SHOE',
  SET_HAND_STARTED: 'SET_HAND_STARTED',
  SET_CARD_EXPOSED: 'SET_CARD_EXPOSED_',
  SET_CARDS_DEALING: 'SET_CARDS_DEALING_',
  SET_BANKER_SET: 'SET_BANKER_SET',
  SET_BANKER_DETERMINATION: 'SET_BANKER_DETERMINATION',
  SET_BANKER_2_CARD_EXPOSED: 'SET_BANKER_2_CARD_EXPOSED',
  SET_BANKER_3_CARD_DEALT: 'SET_BANKER_3_CARD_DEALT',
  SET_BANKER_3_CARD_EXPOSED: 'SET_BANKER_3_CARD_EXPOSED',
  SET_READY_FOR_DEAL: 'SET_READY_FOR_DEAL',
  SET_READY_FOR_SQUEEZE: 'SET_READY_FOR_SQUEEZE',
  SET_READY_FOR_REVEAL: 'SET_READY_FOR_REVEAL',
};

export const BETTING_ROUND_LOG = `Ready to Start Betting Round`;
export const BANKER_DETERMINATION_VALUE = '9';
export const BANKER_DETERMINATION_LOG = `Banker determination - Deal cards until a ${BANKER_DETERMINATION_VALUE} is exposed`;
