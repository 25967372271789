import { FormikProps } from "formik";

export const CUSTOMER_SERVICE_RECORD_TYPE = {
  COMPETITION: "Competition",
  PARTICIPANT: "Participant",
  PLAYER: "Player",
  BET: "Bet",
}

export type CustomerServiceSearchResultType =
  | "COMPETITION"
  | "PARTICIPANT"
  | "PLAYER"
  | "BET"
  
export type RecordDetails = {
  _id: string;
}

export interface CompetitionRecordDetails extends RecordDetails {
  groupId: string;
}

export interface ParticipantRecordDetails  extends RecordDetails {
}

export interface PlayerRecordDetails extends RecordDetails  {
}

export interface BetRecordDetails extends RecordDetails  {
}
  
export type CustomerServiceSearchResult = {
  recordType: string;
  recordId: string;
  summary: string;
  details: RecordDetails;
};

export type CustomerServiceSearchFilter = {
  recordType: string[];
};

export type CustomerServiceSearchQuery = CustomerServiceSearchFilter & {
  page: number;
  limit: number;
  search?: string;
};

export type FormikPropsCustomerServiceSearchView = FormikProps<CustomerServiceSearchFilter>;

export type CustomerServiceSearchSortByQuery = {
  recordType: string[];
  recordId: string[];
  summary: string[];
};
