import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import { Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

export const AVALIABLE_ROLES = {
  BO_ADMINS: 'BO Admins',
  SHOE_TECHNICIANS: 'Shoe Technicians',
  CUSTOMER_SERVICE: 'Customer Service',
};

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children?: ReactNode | string;
};

interface UseRoleList {
  hasAnyOfRoles: (accessibleRoles: string[]) => boolean;
}

export function useUserRoles(): UseRoleList {
  const { assignedRoles } = useAuth();

  const hasAnyOfRoles = (accessibleRoles: string[]) => {
    return assignedRoles.reduce(
      (allowed, role) => accessibleRoles.includes(role) || allowed,
      false
    );
  };
  return { hasAnyOfRoles };
}

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const { hasAnyOfRoles } = useUserRoles();
  const accessDenied = !hasAnyOfRoles(accessibleRoles);
  if (accessDenied) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  if (children) {
    return <>{children}</>;
  }

  return <Outlet />;
}
