import { useEffect, useState } from 'react';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { LiveWaitConfig } from '../@types/liveDealer';
import { LocalStorageKeys, getFromLocalStorage, saveToLocalStorage } from 'utils/localStorage';

export const useLiveWaitConfig = () => {
  const liveWaitConfigFeat = useFeatureValue('live-wait-config', null);
  const [liveWaitConfig, setLiveWaitConfig] = useState<LiveWaitConfig | null>(
    getFromLocalStorage<LiveWaitConfig>(LocalStorageKeys.LIVE_WAIT_CONFIG)
  );

  useEffect(() => {
    if (!liveWaitConfigFeat) return;

    console.log('live-wait-config GB = ', JSON.stringify(liveWaitConfigFeat));
    saveToLocalStorage(LocalStorageKeys.LIVE_WAIT_CONFIG, liveWaitConfigFeat);
    setLiveWaitConfig(liveWaitConfigFeat);
  }, [liveWaitConfigFeat]);

  return liveWaitConfig;
};
