import { ParseSignal } from 'pages/dashboard/game-coordinator/types';

export enum CardReaderType {
  NONE = 'NONE',
  BEETEK_SHOE = 'BEETEK_SHOE',
  OPTICON_HID = 'OPTICON_HID',
  EMULATOR = 'EMULATOR',
}

export interface ICardReaderConfiguration {}

export interface ICOMDeviceConfiguration {
  deviceId: string;
}
export interface BeetekShoeConfiguration extends ICOMDeviceConfiguration {}

export type InitializeInterfaceMethod = () => Promise<void>;
export type ShutDownInterfaceMethod = (isClosing: boolean) => Promise<void>;

export type BeetekSignalHandler = (signal: string, manual?: boolean) => void;

export type OnInitialize = () => void;
export type OnShutdown = () => void;

export type NeedsConfigurationMethod = () => Promise<boolean>;
export type ConfigureMethod = () => Promise<ICardReaderConfiguration | null>;
export type InitializeMethod = (onInitialize: OnInitialize) => Promise<void>;
export type ShutDownMethod = (isClosing: boolean, onShutdown: OnShutdown) => Promise<void>;

export interface ICardReaderDeviceInterface {
  configuration: ICardReaderConfiguration | null;
  needsConfiguration: NeedsConfigurationMethod;
  configure: ConfigureMethod;
  initialize: InitializeInterfaceMethod;
  shutDown: ShutDownInterfaceMethod;
}

export type CardReaderUI = React.FC<any>;

export interface ICardReader {
  cardReaderType: CardReaderType;
  deviceInterface?: ICardReaderDeviceInterface;
  uiComponent?: CardReaderUI;
  needsConfiguration: NeedsConfigurationMethod;
  configure: ConfigureMethod;
  initialize: InitializeMethod;
  shutDown: ShutDownMethod;
}

export interface ISignalParser {
  parseSignal: ParseSignal;
}
