import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { MobileTimePicker } from '@mui/x-date-pickers'
import { FieldInputProps } from 'formik';
import { FC } from 'react'
import { Typography } from '@mui/material';

interface DailyRuleSetupProps {
  getFieldProps: (fieldName: string) => FieldInputProps<any>;
  setFieldValue: (field: string, value: Date) => void;
  disabled: boolean;
}

const DailyRuleSetup: FC<DailyRuleSetupProps> = ({ getFieldProps, setFieldValue, disabled }): JSX.Element => {
  return (
    <Box display='flex' alignItems='center' mt={3} >
      <Typography mr={1}>Start Rule at : </Typography>
      <MobileTimePicker
        {...getFieldProps('startTime')}
        label='Start Time'
        inputFormat="HH:mm"
        onChange={(time) => { setFieldValue('startTime', time) }}
        disabled={disabled}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            sx={{ width: '165px' }}
          />
        )} />
    </Box>
  )
}

export default DailyRuleSetup