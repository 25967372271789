import { getFromLocalStorage, LocalStorageKeys, saveToLocalStorage } from 'utils/localStorage';
import {
  ICardReaderDeviceInterface,
  ICOMDeviceConfiguration,
  ISignalParser,
} from '../../../../../@types/cardReader';
import { SerialPort } from '../../../../../@types/serial';
import { ComReaderWrapper, getComReaderWrapper } from '../../helpers/comStreamWrapper';
import { SIGNAL_TYPES } from '../../types';
import { getSerialPortForDeviceId } from '../../helpers/functions';

export class ComDeviceInterface implements ICardReaderDeviceInterface {
  constructor(signalParser: ISignalParser) {
    this.signalParser = signalParser;
    this.configuration = getFromLocalStorage(LocalStorageKeys.COM_DEVICE_CONFIG);
    this.isReading = getFromLocalStorage<boolean>(LocalStorageKeys.IS_READING) ?? false;
  }

  private port: SerialPort | null = null;
  isReading: boolean = false;

  readerWrapper?: ComReaderWrapper;

  private async setPort() {
    if (this.configuration?.deviceId) {
      this.port = await getSerialPortForDeviceId(this.configuration.deviceId);
    }
  }

  validateConfig = async (): Promise<boolean> => {
    console.log('validateConfig: current configuration: ', JSON.stringify(this.configuration));
    await this.setPort();
    return !!this.port && this.port.getInfo()?.usbProductId === this.configuration?.deviceId;
  };

  configureSerialPort = async (): Promise<ICOMDeviceConfiguration> => {
    this.port = await navigator?.serial?.requestPort();

    if (!this.port)
      throw new Error(
        'Error connecting to COM port - serial lib ' +
          ('serial' in navigator ? 'is ' : 'is NOT ') +
          'available.'
      );

    const portInfo = this.port.getInfo();
    console.log('Selected port - port info => ', JSON.stringify(portInfo));

    return {
      deviceId: portInfo?.usbProductId,
    };
  };

  private async _readLoop() {
    if (!this.port) return;

    this.readerWrapper = getComReaderWrapper(this.port);

    try {
      await this.readData();
    } catch (err) {
      this.handleReadLoopError(err);
    }
  }

  private async readData() {
    if (!this.readerWrapper) return;
    while (true) {
      const { value, done } = await this.readerWrapper.reader.read();
      if (done) {
        this.handleReadDone();
        break;
      }

      if (value) {
        this.handleReadValue(value);
      }
    }
  }

  private handleReadDone() {
    console.info('Reading from the device is done');
    this.readerWrapper?.reader.releaseLock();
  }

  private handleReadValue(value: string) {
    this.signalParser.parseSignal(value);
  }

  private handleReadLoopError(error: any) {
    console.error(`Reading data error: ${error}`);
    this.signalParser.parseSignal('Reading data error. Has the serial device been disconnected?');
  }

  private setIsReading = (isOpen: boolean) => {
    this.isReading = isOpen;
    saveToLocalStorage(LocalStorageKeys.IS_READING, isOpen);
  };

  initialize = async () => {
    if (this.isReading) {
      console.log('Port is already open. Skipping initialization...');
      return;
    }
    this.setIsReading(true);

    // validate the config - this will also load the port info
    const isValid = await this.validateConfig();
    if (!isValid || !this.port) throw new Error('Port not configured');

    console.log('Beetek connect: Opening - port info => ', JSON.stringify(this.port.getInfo()));

    try {
      // these settings should work with both RS232 and USB serial
      await this.port.open({
        baudRate: 9600,
        dataBits: 8,
        parity: 'none',
        bufferSize: 256,
        flowControl: 'none',
      });

      this._readLoop();

      this.signalParser.parseSignal(SIGNAL_TYPES.DEVICE_CONNECTED);
    } catch (err) {
      console.error(`There was an error when opening the serial port: ${err}`);
      this.signalParser.parseSignal(`There was an error when opening the serial port: ${err}`);
      this.setIsReading(false);
    }
  };

  private closePort = async () => {
    this.setIsReading(false);

    await this.readerWrapper?.reader?.cancel();
    await this.readerWrapper?.readerIsClosedPromise
      .catch(() => {
        // this always throws an undefined because the stream is cancelled - ignoring
      })
      .finally(() => {
        console.info('Stream pipe is closed');
      });

    await this.port?.close();
    this.port = null;
    this.readerWrapper = undefined;
  };

  shutDown = async (isClosing: boolean) => {
    // if we're not reading and the port is not open - skip
    if (!this.isReading && !this.port?.readable) return;

    try {
      await this.closePort();
    } catch (err: any) {
      console.error('Error closing port: ' + err);
    }

    this.port = null;

    if (!isClosing) this.signalParser.parseSignal(SIGNAL_TYPES.DEVICE_DISCONNECTED);
  };

  signalParser: ISignalParser;
  configuration: ICOMDeviceConfiguration | null;
  needsConfiguration = async () => {
    const isValid = await this.validateConfig();
    return !isValid;
  };
  configure = async () => {
    this.configuration = await this.configureSerialPort();
    saveToLocalStorage(LocalStorageKeys.COM_DEVICE_CONFIG, this.configuration);
    console.log('configure: new configuration: ', JSON.stringify(this.configuration));
    return this.configuration;
  };
}
