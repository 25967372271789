export function getBettingRoundFromState(s?: string | null): number {
  const match = s?.match(/BET_ROUND_(\d+)_STARTED/);
  return match ? Number(match[1]) : 0;
}
export function getSqueezeRoundFromState(
  s?: string | null,
  fourthBettingRoundFinished?: boolean
): number {
  const match = s?.match(/CARDS_DEALT_(\d+)/);
  if (match) return Number(match[1]);

  if (fourthBettingRoundFinished) return 4;
  if (s === 'BANKER_3_CARD_DEALT') return 5;
  return 0;
}
export function getRevealRoundFromState(s?: string | null): number {
  const match = s?.match(/CARDS_EXPOSING_(\d+)/);
  return match ? Number(match[1]) : 0;
}

export function getDealingRoundFromState(s?: string | null): number {
  const match = s?.match(/CARDS_DEALING_(\d+)/);
  if (match) return Number(match[1]);

  return 0;
}
