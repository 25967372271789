// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import ScriptProvider from './utils/ScriptProvider';
import FeatureFlagsProvider from './utils/FeatureFlagsProvider';
import OpenReplayProvider from 'OpenReplayProvider';

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ScriptProvider url="/env.js">
      <FeatureFlagsProvider>
        <OpenReplayProvider>
          <ThemeConfig>
            <ThemePrimaryColor>
              <ThemeLocalization>
                <RtlLayout>
                  <NotistackProvider>
                    <GlobalStyles />
                    <ProgressBarStyle />
                    <Settings />
                    <ScrollToTop />
                    <GoogleAnalytics />
                    {isInitialized ? <Router /> : <LoadingScreen />}
                  </NotistackProvider>
                </RtlLayout>
              </ThemeLocalization>
            </ThemePrimaryColor>
          </ThemeConfig>
        </OpenReplayProvider>
      </FeatureFlagsProvider>
    </ScriptProvider>
  );
}
