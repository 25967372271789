import {
  CompetitionQuery,
  CompetitionSortByQuery,
} from "../@types/competition";
import { GroupQuery, GroupSortByQuery } from "../@types/group";
import { TemplateQuery } from "../@types/template";
import { ParticipantQuery } from "../@types/participant";
import { CustomerServiceSearchSortByQuery } from "../@types/customerServiceSearch";

export const createRequestQuery = (
  filters:
    | GroupQuery
    | GroupSortByQuery
    | CompetitionQuery
    | TemplateQuery
    | ParticipantQuery
    | CompetitionSortByQuery
    | CustomerServiceSearchSortByQuery,
  queryType: string
): string => {
  let query = "";

  Object.entries(filters).forEach(([option, value]) => {
    if (Array.isArray(value)) {
      if (value.length) {
        query += createQueryFromSingleValue(option, value, queryType);
      }
    } else {
      if (value) {
        if (option === "search") {
          if ((value as string).length > 1) {
            query += `&${option}=${encodeURIComponent(value)}`;
          }
        } else {
          query += `&${option}=${value}`;
        }
      }
    }
  });
  return query;
};

const createQueryFromSingleValue = (
  option: string,
  values: string[],
  queryType: string
): string => {
  return values.reduce(
    (previousValue: string, currentValue: string) =>
      previousValue +
      `&${queryType}[${option}]=${
        currentValue === "social" ? currentValue.toUpperCase() : currentValue
      }`,
    ""
  );
};
