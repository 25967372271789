import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";
import { tokenExpiration } from "./constants/localStorage";

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (idToken: string | null) => {
  if (idToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
    const { exp } = jwtDecode<{ exp: number }>(idToken);
    localStorage.setItem(tokenExpiration, `${exp * 1000}`);
  } else {
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem(tokenExpiration);
  }
};

const getToken = (
  tokenType: "accessToken" | "idToken" | "refreshToken"
): string => {
  const tokenName = Object.keys(window.localStorage).find((key) =>
    key.includes(tokenType)
  ) as string;
  return localStorage.getItem(tokenName) as string;
};

const removeTokens = () => {
  const tokens = Object.keys(window.localStorage).filter(
    (key) =>
      key.includes("accessToken") ||
      key.includes("idToken") ||
      key.includes("refreshToken")
  ) as string[];
  tokens.forEach((key) => localStorage.removeItem(key));
};

export { isValidToken, setSession, verify, sign, getToken, removeTokens };
