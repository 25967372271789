import { Paper, Typography } from "@mui/material";

export default function FilterNotFound({ ...rest }) {
  return (
    <Paper {...rest}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not Found
      </Typography>
      <Typography variant="body2" align="center">
        No results found
      </Typography>
    </Paper>
  );
}
