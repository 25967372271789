import { Pagination } from '../../@types/pagination';

export const rowsPerPage = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
];

export const initialPagination: Pagination = {
  totalPages: 1,
  totalItems: 0,
  page: 1,
  limit: 25,
  offset: 0,
  loading: true,
};
