import { AlarmType, RankType, SuitType } from './mappingTypes';

export const SUIT_VALUES: SuitType[] = [
  { shoeCode: '0', gssCode: 'H', label: 'Hearts' },
  { shoeCode: '1', gssCode: 'D', label: 'Diamonds' },
  { shoeCode: '2', gssCode: 'C', label: 'Clubs' },
  { shoeCode: '3', gssCode: 'S', label: 'Spades' },
];

export const RANK_VALUES: RankType[] = [
  { shoeCode: '0', gssCode: '', label: 'Extra' },
  { shoeCode: '1', gssCode: '1', label: 'Ace' },
  { shoeCode: '2', gssCode: '2', label: '2' },
  { shoeCode: '3', gssCode: '3', label: '3' },
  { shoeCode: '4', gssCode: '4', label: '4' },
  { shoeCode: '5', gssCode: '5', label: '5' },
  { shoeCode: '6', gssCode: '6', label: '6' },
  { shoeCode: '7', gssCode: '7', label: '7' },
  { shoeCode: '8', gssCode: '8', label: '8' },
  { shoeCode: '9', gssCode: '9', label: '9' },
  { shoeCode: 'A', gssCode: 'T', label: '10' },
  { shoeCode: 'B', gssCode: 'J', label: 'Jack' },
  { shoeCode: 'C', gssCode: 'Q', label: 'Queen' },
  { shoeCode: 'D', gssCode: 'K', label: 'King' },
];

export const SHOE_ALARMS: AlarmType[] = [
  { shoeCode: '00', label: 'Generic alarm code' },
  { shoeCode: '01', label: 'Generic alarm code' },
  { shoeCode: '02', label: 'Generic alarm code' },
  { shoeCode: '03', label: 'Card feed alarm. Card did not read correctly.' },
  { shoeCode: '04', label: 'Fast feed alarm. Card pulled too fast to read.' },
  { shoeCode: '05', label: 'Overdraw alarm. Extra card pulled during game.' },
  { shoeCode: '06', label: 'Card pulled after cut card reached' },
  { shoeCode: '07', label: 'Lid alarm' },
  { shoeCode: '08', label: 'Card reverse alarm' },
  { shoeCode: '09', label: 'Card not read alarm' },
  {
    shoeCode: '0A',
    label: 'Alert Cleared - This value indicates the shoe alarm was manually reset.',
  },
  { shoeCode: '0B', label: 'Card draw timer alarm' },
  { shoeCode: '0C', label: 'Collect commission alarm' },
  { shoeCode: '0D', label: 'Gate UP and 1st paper sensor covered' },
  { shoeCode: '0E', label: 'shoe was restarted from diagnostic menu' },
  { shoeCode: '0F', label: 'shoe was restarted from power cycling' },
  { shoeCode: '10', label: 'shoe restarted from RESULTS button hold-down' },
  { shoeCode: '11', label: 'E7 LESS DRAW Alert.' },
  { shoeCode: '12', label: 'Base plate alarm' },
  { shoeCode: '13', label: 'EEPROM Integrity check failed.' },
  { shoeCode: '14', label: 'WriteKeypadWarning.' },
  { shoeCode: '15', label: 'Firmware Integrity check failed.' },
];
