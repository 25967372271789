import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';
import { getRedirectUrl } from './constants/cognito';
import { getToken } from './jwt';
import { LocalStorageKeys, saveToLocalStorage } from './localStorage';

let cognitoUser: CognitoUser;

export const setCognitoUser = (accesToken: string) => {
  if (!cognitoUser) {
    const { username } = jwtDecode<{ username: string }>(accesToken);
    cognitoUser = new CognitoUser({
      Username: username,
      Pool: new CognitoUserPool({
        UserPoolId: window.BACK_OFFICE_CLIENT_ENV.COGNITO_USER_POOL_ID,
        ClientId: window.BACK_OFFICE_CLIENT_ENV.COGNITO_CLIENT_ID,
      }),
    });
  }
};

export const getCognitoUser = () => {
  return cognitoUser;
};

export const updateSessionInLocalStorage = (data: {
  access_token: string;
  id_token: string;
  refresh_token: string;
}) => {
  const { access_token, id_token, refresh_token } = data;
  setCognitoUser(access_token);
  cognitoUser.setSignInUserSession(
    new CognitoUserSession({
      AccessToken: new CognitoAccessToken({ AccessToken: access_token }),
      IdToken: new CognitoIdToken({ IdToken: id_token }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: refresh_token }),
    })
  );
  saveToLocalStorage(LocalStorageKeys.ID_TOKEN, id_token);
  return id_token;
};

export const getSession = async (code: string) => {
  try {
    const response = await fetch(
      `${
        window.BACK_OFFICE_CLIENT_ENV.COGNITO_AUTH_URL
      }/oauth2/token?grant_type=authorization_code&code=${code}&client_id=${
        window.BACK_OFFICE_CLIENT_ENV.COGNITO_CLIENT_ID
      }&redirect_uri=${getRedirectUrl()}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      }
    );
    const data = await response.json();
    return updateSessionInLocalStorage(data);
  } catch (err) {
    console.error(err);
  }
};

export const refreshSession = async (): Promise<string> => {
  const refreshToken = getToken('refreshToken');
  const cognitoRefreshToken = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(cognitoRefreshToken, (err, data) => {
      if (!err) {
        updateSessionInLocalStorage({
          access_token: data.accessToken.jwtToken,
          id_token: data.idToken.jwtToken,
          refresh_token: data.refreshToken.token,
        });
        return resolve(data.idToken.jwtToken);
      }
      return reject(err);
    });
  });
};
