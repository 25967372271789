export class LineBreakTransformer {
  container: any = '';
  private lineEndRegex = /\r?\n|\r|\n/g;

  constructor() {
    this.container = '';
  }

  transform(chunk: any, controller: any) {
    this.container += chunk;
    const lines = this.container.split(this.lineEndRegex);
    this.container = lines.pop();
    lines.forEach((line: any) => controller.enqueue(line));
  }

  flush(controller: any) {
    controller.enqueue(this.container);
  }
}
