import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useFormik, FormikProvider, Form } from 'formik'
import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import DailyRuleSetup from './DailyRuleSetup'
import WeeklyRuleSetup from './WeeklyRuleSetup'
import MonthlyRuleSetup from './MonthlyRuleSetup'
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs'
import Page from 'components/Page'
import { PATH_DASHBOARD } from 'routes/paths'
import useSettings from 'hooks/useSettings'
import useIsMountedRef from 'hooks/useIsMountedRef'
import axios from 'utils/axios'
import { Frequency, convertToCronPattern, formatTime, getFormikValues } from './helpers'
import ConfirmationDialog from 'components/_dashboard/ConfirmationDialog'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';

const TITLE = 'White Diamonds Giveaway';
const CONFIRMATION_DIALOG_TITLE = 'Are you sure you want to update the giveaway rules?';

const useStyles = makeStyles(() => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  }
}))
type StateType = 'ENABLED' | 'DISABLED';
const FREQUENCY_OPTIONS: Frequency[] = ['daily', 'weekly', 'monthly'];

interface FormikValues {
  state: StateType | null;
  startTime: Date | null;
  daysOfWeek: string | null;
  dayOfMonth: string | null;
  scheduleRate: Frequency | null;
}

const WDGiveAwayForm = () => {
  const [giveAwayRules, setGiveAwayRules] = useState<FormikValues | null>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const { themeStretch } = useSettings();
  const isMountedRef = useIsMountedRef();
  const ruleEnabled = giveAwayRules?.state === 'ENABLED';
  const handleState = (state: StateType): StateType => {
    return state === 'ENABLED' ? 'DISABLED' : "ENABLED";
  };

  useEffect(() => {
    setLoadingData(true);
    (async () => {
      try {
        if (isMountedRef.current) {
          const response = await axios.get(
            `${window.BACK_OFFICE_CLIENT_ENV.LEDGER_LAYER_BASE_URL}/schedule-giveaway`
          );
          const formikValues = getFormikValues(response.data.ScheduleExpression);
          setGiveAwayRules({
            state: response.data.State,
            ...formikValues,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingData(false);
      }
    })();
  }, [isMountedRef]);

  const handleSubmit = (formikValues: FormikValues) => {
    const cronDataValues = {
      minutes: formikValues.startTime ? new Date(formikValues.startTime).getMinutes().toString() : null,
      hours: formikValues.startTime ? new Date(formikValues.startTime).getHours().toString() : null,
      dayOfMonth: formikValues.dayOfMonth ?? null,
      daysOfWeek: formikValues.daysOfWeek ?? null,
    };

    setIsSubmitting(true);
    (async () => {
      try {
        await axios.post(
          `${window.BACK_OFFICE_CLIENT_ENV.LEDGER_LAYER_BASE_URL}/schedule-giveaway`,
          {
            ScheduleExpression: convertToCronPattern(cronDataValues),
            State: formikValues?.state,
          }
        );
      } catch (err) {
        console.log(err);
      } finally {
        setGiveAwayRules(formikValues);
        setIsSubmitting(false);
        setOpenConfirmDialog(false)
      }
    })();
  }

  const initialValues: FormikValues = {
    state: giveAwayRules?.state ?? null,
    startTime: giveAwayRules?.startTime ?? null,
    daysOfWeek: giveAwayRules?.daysOfWeek ?? null,
    dayOfMonth: giveAwayRules?.dayOfMonth ?? null,
    scheduleRate: giveAwayRules?.scheduleRate ?? null,
  }
  const formik = useFormik({
    initialValues,
    onSubmit: () => setOpenConfirmDialog(true),
    enableReinitialize: true,
  });
  const { values, setFieldValue, getFieldProps } = formik;

  const textConfig: Record<Frequency, string> = {
    daily: `at ${formatTime(giveAwayRules?.startTime)}`,
    weekly: `on ${giveAwayRules?.daysOfWeek} at ${formatTime(giveAwayRules?.startTime)}`,
    monthly: `on ${giveAwayRules?.dayOfMonth}. day at ${formatTime(giveAwayRules?.startTime)}`,
  };

  return (
    <Page title={TITLE}>
      {loadingData ? <Box display='flex' justifyContent='center'>
        <CircularProgress />
      </Box> :
        <Container maxWidth={themeStretch ? false : "lg"}>
          <HeaderBreadcrumbs
            heading={TITLE}
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              { name: "Maintenance", href: PATH_DASHBOARD.maintenance.list },
              { name: TITLE },
            ]}
          />
          {ruleEnabled ? (
            <Typography color='green'>
              The rule is set {giveAwayRules.scheduleRate} {textConfig[giveAwayRules.scheduleRate as Frequency]}
            </Typography>
          ) : (
            <Typography variant="body2" color="red">
              Giveaway rules are disabled
            </Typography>
          )}
          <FormikProvider value={formik} >
            <Form noValidate className={classes.formWrapper} >
              <Box display="flex" justifyContent='space-between' alignItems='center' flexGrow='1'>
                <FormControlLabel
                  control={<Checkbox {...getFieldProps('state')} onChange={() => {
                    setFieldValue('state', handleState(values?.state as StateType));
                  }}
                    checked={values?.state === 'ENABLED'} />}
                  label="Enable Giveaway Rules"
                />
              </Box>
              <>
                <FormControl sx={{ width: 300, display: 'flex' }}>
                  <Select
                    displayEmpty
                    renderValue={(value) => `${value}`}
                    {...getFieldProps("scheduleRate")}
                    disabled={!ruleEnabled}
                    onChange={(e) => {
                      setFieldValue('dayOfMonth', null)
                      setFieldValue('daysOfWeek', null)
                      setFieldValue('scheduleRate', e.target.value)
                    }}
                  >
                    {FREQUENCY_OPTIONS.map((value) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Box m={3}>
                  {values.scheduleRate === 'weekly' &&
                    <WeeklyRuleSetup daysOfWeek={values.daysOfWeek} updateField={setFieldValue} disabled={!ruleEnabled} />
                  }
                  {values.scheduleRate === 'monthly' &&
                    <MonthlyRuleSetup getFieldProps={getFieldProps} disabled={!ruleEnabled} />
                  }
                  <DailyRuleSetup getFieldProps={getFieldProps} setFieldValue={setFieldValue} disabled={!ruleEnabled} />
                </Box>
              </>
              <Button sx={{ maxWidth: '300px' }} type="submit">Submit</Button>
            </Form>
          </FormikProvider>
          <ConfirmationDialog
            openDialog={openConfirmDialog}
            handleClose={() => setOpenConfirmDialog(false)}
            title={CONFIRMATION_DIALOG_TITLE}
            handleSubmit={() => handleSubmit(values as FormikValues)}
            isSubmitting={isSubmitting}
          />
        </Container>}
    </Page>
  )
}

export default WDGiveAwayForm;
