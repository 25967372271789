import { filter } from "lodash";
import { Chip, Stack } from "@mui/material";
import {
  FormikPropsMarketplaceView,
  MarketplaceFilter,
} from "../../../../@types/marketplace";
import { MARKETPLACE_FILTERS } from "utils/constants/filters";
import { LabelStyle, WrapperStyle } from "../styles/styles";

type MarketplaceFiltersOverviewProps = {
  formik: FormikPropsMarketplaceView;
  filters: MarketplaceFilter;
};

export default function MarketplaceFiltersOverview({
  formik,
  filters,
}: MarketplaceFiltersOverviewProps) {
  const { handleSubmit, setFieldValue } = formik;

  const handleRemoveStatus = (filterName: string, value: string) => {
    const newValue = filter(
      filters[`${filterName}` as keyof MarketplaceFilter],
      (filterValue) => filterValue !== value
    );
    handleSubmit();
    setFieldValue(filterName, newValue);
  };

  return (
    <>
      {MARKETPLACE_FILTERS.map(
        (filter) =>
          !!filters[`${filter.name}` as keyof MarketplaceFilter].length && (
            <WrapperStyle key={filter.name}>
              <LabelStyle>{filter.label}:</LabelStyle>
              <Stack direction="row" flexWrap="nowrap" sx={{ p: 0.75 }}>
                {filters[`${filter.name}` as keyof MarketplaceFilter].map(
                  (value) => (
                    <Chip
                      key={value}
                      label={value}
                      size="small"
                      onDelete={() => handleRemoveStatus(filter.name, value)}
                      sx={{ m: 0.5 }}
                    />
                  )
                )}
              </Stack>
            </WrapperStyle>
          )
      )}
    </>
  );
}
