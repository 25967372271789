import {
  Stack,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Tooltip,
  Grid,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { MARKETPLACE_FILTERS } from "utils/constants/filters";
import {
  FormikPropsMarketplaceView,
  MarketplaceFilter,
} from "../../../../@types/marketplace";
import Scrollbar from "components/Scrollbar";
type MarketplaceFiltersSidebarProps = {
  formik: FormikPropsMarketplaceView;
};

export default function MarketplaceFiltersSidebar({
  formik,
}: MarketplaceFiltersSidebarProps) {
  const { values, getFieldProps } = formik;

  return (
    <Scrollbar>
      <Stack spacing={3} sx={{ p: 3 }}>
        {MARKETPLACE_FILTERS.map((filter) => (
          <div key={filter.name}>
            {filter.name !== "countries" ? (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  {filter.label}
                </Typography>
                <FormGroup>
                  {filter.values.map((value) => {
                    let filterValue = value as {
                      value: string;
                      name: string;
                    };
                    return (
                      <FormControlLabel
                        key={filterValue.value}
                        control={
                          <Checkbox
                            {...getFieldProps(filter.name)}
                            value={filterValue.value}
                            checked={values[
                              `${filter.name}` as keyof MarketplaceFilter
                            ].includes(filterValue.value)}
                          />
                        }
                        label={value.name}
                      />
                    );
                  })}
                </FormGroup>
              </>
            ) : (
              <>
                <Grid container alignItems={"center"} mb={"18px"}>
                  <Typography variant="subtitle1">{filter.label}</Typography>
                  <Tooltip
                    title={
                      <Typography>
                        You can select multiple values from the dropdown
                      </Typography>
                    }
                    sx={{ marginLeft: "10px" }}
                  >
                    <InfoIcon fontSize={"small"} />
                  </Tooltip>
                </Grid>
                <FormControl sx={{ width: 200 }}>
                  <InputLabel
                    id="select-label"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Choose countries
                  </InputLabel>

                  <Select
                    multiple
                    displayEmpty
                    label="Choose countries"
                    renderValue={(value) => `${value}`}
                    {...getFieldProps("countries")}
                  >
                    {filter.values?.map((value, index) => {
                      const country = value as {
                        value: string;
                        name: string;
                      };
                      return (
                        <MenuItem key={index} value={country.value}>
                          {country.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )}
          </div>
        ))}
      </Stack>
    </Scrollbar>
  );
}
