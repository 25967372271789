import { HandleSignal, Signal, ParseSignal, SIGNAL_TYPES } from '../../types';
import { RANK_VALUES, SUIT_VALUES } from '../cardMappings/hanXinMappins';
import { SuitType } from '../cardMappings/mappingTypes';

const pattern: RegExp = /^TP(\d+)-([1-4])([0-1]\d)0-(\d+)$/;

export const parseInputByRegex = (inputData: string): Signal => {
  console.log(`parseInputByRegex: input = ${inputData}`);

  const match: RegExpExecArray | null = pattern.exec(inputData);

  if (match) {
    const number: string = match[1];
    const suit: number = Number(match[2]);
    const rank: string = match[3];
    const checksum: number = Number(match[4]);

    console.log('Number:', number);
    console.log('Suit:', suit);
    console.log('Rank:', rank);
    console.log('Checksum:', checksum);
    console.log('input.length:', inputData.length);
    if (checksum === 5) {
      const suitVal: SuitType = SUIT_VALUES[suit];
      const rankVal = RANK_VALUES[rank];
      console.log(`Drew card: ${rankVal.label} of ${suitVal.label}`);
      return {
        type: SIGNAL_TYPES.NEW_CARD,
        value: `${suitVal.gssCode}${rankVal.gssCode}`,
      };
    }
  }
  return { type: SIGNAL_TYPES.ALERT, value: inputData };
};

export class RegexParser {
  constructor(handleSignal: HandleSignal) {
    this.handleSignal = handleSignal;
  }
  private handleSignal: HandleSignal;

  parseSignal: ParseSignal = (inputData: string) => {
    console.log(`RegexParser: Received inputData [${inputData}]`);
    return this.handleSignal(parseInputByRegex(inputData));
  };
}
