export interface GameStateOdds {
  oddId: string;
  title: string;
  value: number;
}

export type BettingRoundData = {
  startBettingRound: string;
  endBettingRound: string;
};

export interface GameStateTablePlayers {
  [key: number]: GameStatePlayer;
}

export interface GameStatePlayer {
  seat: number;
  isBanker: boolean;
  cards: string[];
  playerName: string;
  odds: GameStateOdds[];
  dealtCards: string[];
  playerId: string;
  numCardsDealt: number;
  numCardsRevealed: number;
  revealedCardDatas?: CardData[];
  shouldBankerGet3rdCard?: boolean;
}

export interface CardData {
  code: string;
  suit: Suit;
  rank: string;
  label: string;
  value: number;
}

export interface Suit {
  suit: string;
  symbol: string;
  color: string;
}

export const suits: Suit[] = [
  { suit: 'H', symbol: '♥', color: 'red' },
  { suit: 'D', symbol: '♦', color: 'blue' },
  { suit: 'C', symbol: '♣', color: 'green' },
  { suit: 'S', symbol: '♠', color: 'black' },
];
export const ranks = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];
export const SuitMap: { [key: string]: Suit } = {
  H: suits[0],
  D: suits[1],
  C: suits[2],
  S: suits[3],
};
