import { filter } from "lodash";
import { Chip, Stack } from "@mui/material";
import { FormikPropsGroupView, GroupFilter } from "../../../../@types/group";
import { LabelStyle, WrapperStyle } from "../styles/styles";

type GroupFiltersOverviewProps = {
  formik: FormikPropsGroupView;
  filters: GroupFilter;
};

export default function GroupFilteresOverview({
  formik,
  filters,
}: GroupFiltersOverviewProps) {
  const { handleSubmit, setFieldValue } = formik;
  const { status, game } = filters;

  const handleRemoveStatus = (value: string) => {
    const newValue = filter(status, (_item) => _item !== value);
    handleSubmit();
    setFieldValue("status", newValue);
  };

  const handleRemoveGame = (value: string) => {
    const newValue = filter(game, (_item) => _item !== value);
    handleSubmit();
    setFieldValue("game", newValue);
  };

  return (
    <>
      {!!status.length && (
        <WrapperStyle>
          <LabelStyle>status:</LabelStyle>
          <Stack direction="row" flexWrap="nowrap" sx={{ p: 0.75 }}>
            {status.map((status) => (
              <Chip
                key={status}
                label={status}
                size="small"
                onDelete={() => handleRemoveStatus(status)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
      {!!game.length && (
        <WrapperStyle>
          <LabelStyle>game:</LabelStyle>
          <Stack direction="row" flexWrap="nowrap" sx={{ p: 0.75 }}>
            {game.map((game) => (
              <Chip
                key={game}
                label={game}
                size="small"
                onDelete={() => handleRemoveGame(game)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
    </>
  );
}
