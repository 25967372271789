import { SerialPort } from '../../../../@types/serial';
import { LineBreakTransformer } from './lineBreakTransformer';

export type ComReaderWrapper = {
  readerIsClosedPromise: Promise<any>;
  reader: ReadableStreamDefaultReader;
};

export const getComReaderWrapper = (port: SerialPort): ComReaderWrapper => {
  const decoder = new TextDecoderStream();

  return {
    readerIsClosedPromise: port.readable.pipeTo(decoder.writable),
    reader: decoder.readable
      .pipeThrough(new TransformStream(new LineBreakTransformer()))
      .getReader(),
  };
};
