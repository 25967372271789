import { filter } from 'lodash';
import { Chip, Stack } from '@mui/material';
import { LabelStyle, WrapperStyle } from '../styles/styles';
import {
  CustomerServiceSearchFilter,
  FormikPropsCustomerServiceSearchView,
} from '../../../../@types/customerServiceSearch';

type CustomerServiceSearchFiltersOverviewProps = {
  formik: FormikPropsCustomerServiceSearchView;
  filters: CustomerServiceSearchFilter;
};

export default function CustomerServiceSearchFiltersOverview({
  formik,
  filters,
}: CustomerServiceSearchFiltersOverviewProps) {
  const { handleSubmit, setFieldValue } = formik;
  const { recordType } = filters;

  const handleRemoveRecordType = (value: string) => {
    const newValue = filter(recordType, (_item) => _item !== value);
    handleSubmit();
    setFieldValue('recordType', newValue);
  };

  return (
    <>
      {!!recordType.length && (
        <WrapperStyle>
          <LabelStyle>Record type:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {recordType.map((type) => (
              <Chip
                key={type}
                label={type}
                size="small"
                onDelete={() => handleRemoveRecordType(type)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}
    </>
  );
}
