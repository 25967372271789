import {
  Stack,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
} from "@mui/material";
import Scrollbar from "../../../Scrollbar";
import { FormikPropsGroupView } from "../../../../@types/group";
import {
  COMPETITION_STATUS_FILTER,
  GROUP_GAME_FILTER,
} from "utils/constants/filters";

type GroupFiltersSidebarProps = {
  formik: FormikPropsGroupView;
};

export default function CompetitionFiltersSidebar({
  formik,
}: GroupFiltersSidebarProps) {
  const { values, getFieldProps } = formik;

  return (
    <Scrollbar>
      <Stack spacing={3} sx={{ p: 3 }}>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Status
          </Typography>
          <FormGroup>
            {COMPETITION_STATUS_FILTER.map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    {...getFieldProps("status")}
                    value={item}
                    checked={values.status.includes(item)}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>

        <div>
          <Typography variant="subtitle1" gutterBottom>
            Game
          </Typography>
          <FormGroup>
            {GROUP_GAME_FILTER.map((game, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    {...getFieldProps("game")}
                    value={game}
                    checked={values.game.includes(game)}
                  />
                }
                label={game}
              />
            ))}
          </FormGroup>
        </div>
      </Stack>
    </Scrollbar>
  );
}
