import { Icon } from '@iconify/react';
// material
import { alpha } from '@mui/material/styles';
import { Button } from '@mui/material';
import useLocalStorage from 'hooks/useLocalStorage';
import { LocalStorageKeys } from 'utils/localStorage';

// ----------------------------------------------------------------------

export default function SettingStudioApi() {
  const [studioApiUrl, setStudioApiUrl] = useLocalStorage(LocalStorageKeys.STUDIO_API_URL, null);

  const onClick = () => {
    const newUrl = prompt('Enter the Studio API URL:', studioApiUrl);
    if (newUrl) setStudioApiUrl(newUrl);
  };

  return (
    <Button
      fullWidth
      size="large"
      variant="outlined"
      color={studioApiUrl ? 'primary' : 'inherit'}
      startIcon={<Icon icon="hugeicons:api" />}
      onClick={onClick}
      sx={{
        fontSize: 14,
        ...(studioApiUrl && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        }),
      }}
    >
      {studioApiUrl}
    </Button>
  );
}
