import {
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Dispatch, SetStateAction } from "react";
import { rowsPerPage } from "utils/constants/pages";
import { Pagination as GroupPagination } from "../../../@types/pagination";

interface TableFooterProps {
  pagination: GroupPagination;
  disableLoading?: boolean;
  setPagination: Dispatch<SetStateAction<GroupPagination>>;
  handleRows(event: SelectChangeEvent<number>): void;
}

export default function TableFooter({
  pagination,
  disableLoading,
  setPagination,
  handleRows,
}: TableFooterProps) {
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        height: "64px",
        borderTop: "solid 1px rgba(145, 158, 171, 0.24)",
        padding: "0 24px",
      }}
    >
      <Grid item xs={3}>
        <Typography>Total count:&nbsp;{pagination.totalItems}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={9}
        alignItems="center"
        justifyContent={"flex-end"}
      >
        <Typography>Rows per page:</Typography>
        <Select
          onChange={(e) => handleRows(e)}
          value={pagination.limit}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "0",
            },
          }}
        >
          {rowsPerPage.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Pagination
          count={pagination.totalPages}
          page={pagination.page}
          onChange={(e, page) => {
            if (page && page !== pagination.page) {
              setPagination({
                ...pagination,
                page,
                loading: disableLoading ? false : true,
              });
            }
          }}
          size="small"
          disabled={pagination.loading}
        />
      </Grid>
    </Grid>
  );
}
