import { Outlet } from 'react-router';
import { CardReaderStateProvider } from './CardReaderStateProvider';
import { GameCoordinatorStateProvider } from './GameCoordinatorStateProvider';
import { LiveDealerHelperStateProvider } from 'components/live-dealer-helper/LiveDealerHelperStateProvider';

const StudioContextLayout = () => (
  <GameCoordinatorStateProvider>
    <LiveDealerHelperStateProvider>
      <CardReaderStateProvider>
        <Outlet />
      </CardReaderStateProvider>
    </LiveDealerHelperStateProvider>
  </GameCoordinatorStateProvider>
);

export default StudioContextLayout;
