import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useCardReaderContext } from '../CardReaderStateProvider';

const BeetekUI = () => {
  const { currentConfiguration, configureCardReader, shutDownCardReader } = useCardReaderContext();

  const handleChangeConfigClick = () => {
    shutDownCardReader(false);
    configureCardReader();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin={3}
      border="1px solid "
      overflow="auto"
    >
      <Typography mb={1} variant="h6">
        Beetek Shoe Card Reader
      </Typography>
      <Typography textAlign="center" variant="h6" m={1}>
        Current Configuration Data: {currentConfiguration}
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={handleChangeConfigClick}
        sx={{ maxWidth: '300px', margin: '100px auto' }}
      >
        Change COM Port
      </Button>
    </Box>
  );
};

export default BeetekUI;
