import React, { useEffect, useMemo } from 'react';
import Tracker from '@openreplay/tracker';
import useAuth from 'hooks/useAuth';

interface Props {
  children: React.ReactElement;
}

const OpenReplayProvider = ({ children }: Props) => {
  const { user } = useAuth();
  const tracker = useMemo(
    () =>
      new Tracker({
        projectKey: window.BACK_OFFICE_CLIENT_ENV.OPEN_REPLAY_KEY,
        network: {
          capturePayload: true,
          failuresOnly: false,
          sessionTokenHeader: false,
          ignoreHeaders: ['Cookie', 'Set-Cookie', 'Authorization'],
          captureInIframes: true,
        },
      }),
    []
  );

  useEffect(() => {
    if (user?.['cognito:username']) {
      tracker.start({
        userID: user['cognito:username'],
      });

      return () => {
        tracker.stop();
      };
    }
  }, [tracker, user]);

  return children;
};

export default OpenReplayProvider;
