import {
  CardReaderType,
  CardReaderUI,
  ICardReader,
  ICardReaderDeviceInterface,
  OnInitialize,
  OnShutdown,
} from '../../../../@types/cardReader';
import BeetekUI from './BeetekUI';

export class BeetekShoeCardReader implements ICardReader {
  constructor(deviceInterface: ICardReaderDeviceInterface) {
    this.deviceInterface = deviceInterface;
  }

  deviceInterface: ICardReaderDeviceInterface;

  cardReaderType = CardReaderType.BEETEK_SHOE;
  needsConfiguration = async () => await this.deviceInterface.needsConfiguration();
  configure = async () => await this.deviceInterface.configure();
  initialize = async (onInitialize: OnInitialize) => {
    await this.deviceInterface?.initialize();
    onInitialize();
  };
  shutDown = async (isClosing: boolean, onShutdown: OnShutdown) => {
    await this.deviceInterface?.shutDown(isClosing);
    if (!isClosing) onShutdown();
  };
  uiComponent: CardReaderUI = BeetekUI;
}
