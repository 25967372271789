import { Signal, HandleSignal, SIGNAL_TYPES, sendAlertSignal, ParseSignal } from '../../types';
import { SUIT_VALUES, RANK_VALUES, SHOE_ALARMS } from '../cardMappings/beetekMappings';
import { ISignalParser } from '../../../../../@types/cardReader';

export const parseCard = (signal: string): Signal => {
  const suitValue = signal.slice(3, 4);
  const rankValue = signal.slice(4, 5);

  const suit = SUIT_VALUES.find((a) => a.shoeCode === suitValue);
  const rank = RANK_VALUES.find((a) => a.shoeCode === rankValue);

  if (!!suit && !!rank) {
    return {
      type: SIGNAL_TYPES.NEW_CARD,
      value: `${suit.gssCode}${rank.gssCode}`,
    };
  }
  return { type: SIGNAL_TYPES.ALERT, value: `Unknown Card: ${signal}` };
};

export const parseAlarm = (signal: string): Signal => {
  const alarmValue = signal.slice(3, 5);
  const alarm = SHOE_ALARMS.find((a) => a.shoeCode === alarmValue);
  return {
    type: SIGNAL_TYPES.ALERT,
    value: alarm?.label ? alarm.label : `Unknown Alert: ${signal}`,
  };
};

export const parseBeetekSignal = (input: string): Signal => {
  console.log(`parseBeetekSignal: Received input [${input}]`);

  let signal = input.trimEnd();

  if (signal.startsWith('*')) {
    let messageIndex = signal.slice(1, 3);

    switch (messageIndex) {
      case '0D':
        return parseCard(signal);
      case '08':
        return parseAlarm(signal);
    }
  }
  return { type: SIGNAL_TYPES.ALERT, value: `Unknown Signal: ${signal}` };
};

export const checksumBeetekSignal = (input: string): boolean => {
  let signal = input.trimEnd();
  let sum = 0;
  for (let a = 1; a < signal.length - 2; a++) {
    sum += signal.charCodeAt(a);
  }
  let checksum = '0x' + signal.slice(-2);
  if (parseInt(checksum) === sum) {
    return true;
  }
  console.log(`Bad checksum: ${checksum} != ${sum}`);
  return false;
};

export class BeetekShoeParser implements ISignalParser {
  constructor(handleSignal: HandleSignal) {
    this.handleSignal = handleSignal;
  }
  private handleSignal: HandleSignal;

  handleGssSignal = (signal: string) => {
    if (!checksumBeetekSignal(signal))
      return sendAlertSignal(this.handleSignal, `Checksum failed for [${signal}]`);

    return this.handleSignal(parseBeetekSignal(signal));
  };

  parseSignal: ParseSignal = (inputData: string) => {
    console.log(`handleBeetekSignal: Received inputData [${inputData}]`);
    if (inputData.startsWith('*')) return this.handleGssSignal(inputData);

    return sendAlertSignal(this.handleSignal, inputData);
  };
}
