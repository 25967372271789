import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FieldInputProps } from 'formik';
import React from 'react'

interface MonthlyRuleSetupProps {
  getFieldProps: (fieldName: string) => FieldInputProps<any>;
  disabled: boolean;
}

const MonthlyRuleSetup: React.FC<MonthlyRuleSetupProps> = ({ getFieldProps, disabled }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Typography mr={1}>Start Rule on : </Typography>
      <FormControl sx={{ width: '165px' }}>
        <InputLabel
          id="select-label"
          sx={{ display: "flex", alignItems: "center" }}
        >
          Day of month
        </InputLabel>
        <Select
          label="Day Of Month"
          renderValue={(value) => `${value}`}
          {...getFieldProps("dayOfMonth")}
          disabled={disabled}
        >
          {Array(31).fill(0).map((_, index) => {
            return (
              <MenuItem key={index} value={index + 1}>
                {index + 1}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default MonthlyRuleSetup;