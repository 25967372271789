interface CronPatternData {
  minutes: string | null;
  hours: string | null;
  dayOfMonth: string | null;
  daysOfWeek: string | null;
}

export type Frequency = 'daily' | 'weekly' | 'monthly';

export const convertToCronPattern = (cronData: CronPatternData) => {
  const { minutes, hours, dayOfMonth, daysOfWeek } = cronData;
  const getCorrectSign = (value: string | null) => {
    return value !== null ? '?' : '*';
  };
  return `${minutes ?? '*'} ${hours ?? '*'} ${dayOfMonth ?? getCorrectSign(daysOfWeek)} * ${
    daysOfWeek ?? getCorrectSign(dayOfMonth ?? '*')
  } *`;
};

const getValueOrEmpty = (value: string): string | null => {
  return value !== '*' && value !== '?' ? value : null;
};

const convertFromCronPattern = (cronPattern: string): CronPatternData => {
  const cronParts: string[] = cronPattern.split(' ');

  const cronData: CronPatternData = {
    minutes: getValueOrEmpty(cronParts[0]),
    hours: getValueOrEmpty(cronParts[1]),
    dayOfMonth: getValueOrEmpty(cronParts[2]),
    daysOfWeek: getValueOrEmpty(cronParts[4]),
  };

  return cronData;
};

const getFrequency = (cronPattern: string): Frequency => {
  const cronParts = cronPattern.split(' ');
  if (cronParts[2] !== '*' && cronParts[4] === '?') {
    return 'monthly';
  } else if (cronParts[4] !== '*' && cronParts[2] === '?') {
    return 'weekly';
  } else {
    return 'daily';
  }
};

const getStartTime = (cronPattern: string): Date | null => {
  const cronParts = cronPattern.split(' ');
  if (cronParts[1] === '*' && cronParts[0] === '*') {
    return null;
  }
  const date = new Date();
  date.setHours(parseInt(cronParts[1]));
  date.setMinutes(parseInt(cronParts[0]));
  return date;
};

export const getFormikValues = (cronPattern: string) => {
  const cronData = convertFromCronPattern(cronPattern);
  const frequency = getFrequency(cronPattern);
  const startTime = getStartTime(cronPattern);

  return {
    startTime,
    daysOfWeek: cronData.daysOfWeek,
    dayOfMonth: cronData.dayOfMonth,
    scheduleRate: frequency,
  };
};

export const formatTime = (time?: Date | null) =>
  time
    ? ('0' + new Date(time).getHours()).slice(-2) +
      ':' +
      ('0' + new Date(time).getMinutes()).slice(-2)
    : '';
