import {
  Stack,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
} from "@mui/material";
import Scrollbar from "../../../Scrollbar";
import { FormikPropsCompetitionView } from "../../../../@types/competition";
import {
  COMPETITION_STATUS_FILTER,
  COMPETITION_SEARCH_TYPE_FILTER,
  COMPETITION_TYPE_FILTER,
} from "utils/constants/filters";

type CompetitionFiltersSidebarProps = {
  formik: FormikPropsCompetitionView;
};

export default function CompetitionFiltersSidebar({
  formik,
}: CompetitionFiltersSidebarProps) {
  const { values, getFieldProps } = formik;

  return (
    <Scrollbar>
      <Stack spacing={3} sx={{ p: 3 }}>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Status
          </Typography>
          <FormGroup>
            {COMPETITION_STATUS_FILTER.map((item) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    {...getFieldProps("status")}
                    value={item}
                    checked={values.status.includes(item)}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>

        <div>
          <Typography variant="subtitle1" gutterBottom>
            Type
          </Typography>
          <FormGroup>
            {COMPETITION_SEARCH_TYPE_FILTER.map((item) => (
              <FormControlLabel
                key={item.value}
                control={
                  <Checkbox
                    {...getFieldProps("competitionSearchType")}
                    value={item.value}
                    checked={values.competitionSearchType.includes(item.value)}
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </div>

        <div>
          <Typography variant="subtitle1" gutterBottom>
            Contest Type
          </Typography>
          <FormGroup>
            {COMPETITION_TYPE_FILTER.map((item) => (
              <FormControlLabel
                key={item.value}
                control={
                  <Checkbox
                    {...getFieldProps("competitionType")}
                    value={item.value}
                    checked={values.competitionType.includes(item.value)}
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </div>
      </Stack>
    </Scrollbar>
  );
}
