import { createContext, ReactNode, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import { getToken, isValidToken, removeTokens, setSession } from "../utils/jwt";
import { ActionMap, AuthState, AuthUser, JWTContextType } from "../@types/authentication";
import { getCognitoUser, getSession, setCognitoUser } from "utils/cognito";
import { handleInactivity } from "utils/handleInactivity";
import { getRedirectUrl } from "utils/constants/cognito";
import { useNavigate } from "react-router-dom";

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    assignedRoles: string[];
  };
  [Types.Login]: {
    user: AuthUser;
    assignedRoles: string[];
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
    assignedRoles: string[];
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  assignedRoles: [],
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        assignedRoles: action.payload.assignedRoles,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        assignedRoles: action.payload.assignedRoles,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        assignedRoles: [],
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        assignedRoles: action.payload.assignedRoles,
      };

    default:
      return state;
  }
};

let code = "";
const parsedSearch = window.location.search.split("=");
if (parsedSearch[0] === "?code") {
  code = parsedSearch[1];
}

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
    const cognitoUser = getCognitoUser();
    cognitoUser.signOut();
    window.location.href = getRedirectUrl();
  };

  useEffect(() => {
    const handleNewSession = (idToken: string) => {
      let assignedRoles: string[] = [];
      const user = jwtDecode<AuthUser>(idToken);
      if (user && user["custom:Roles"]) {
        assignedRoles = user["custom:Roles"]
          .slice(1, -1)
          .split(",")
          .map((role) => role.trim());
      }
      setSession(idToken);
      handleInactivity();
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user,
          assignedRoles,
        },
      });
      if (code) {
        code = "";
        navigate({ search: "" }, { replace: true });
      }
    };

    const initialize = async () => {
      const accessToken = getToken("accessToken");
      const idToken = getToken("idToken");
      if (accessToken && isValidToken(accessToken)) {
        setCognitoUser(accessToken);
        handleNewSession(idToken);
      } else {
        if (accessToken) {
          removeTokens();
        }
        if (code) {
          const idToken = (await getSession(code)) as string;
          handleNewSession(idToken);
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              assignedRoles: [],
            },
          });
          window.location.href = `${
            window?.BACK_OFFICE_CLIENT_ENV?.COGNITO_AUTH_URL
          }/login?client_id=${
            window?.BACK_OFFICE_CLIENT_ENV?.COGNITO_CLIENT_ID
          }&response_type=code&scope=email+openid+profile&redirect_uri=${getRedirectUrl()}`;
        }
      }
    };

    initialize();
    document.addEventListener("inactivityException", () => logout());
  }, [navigate]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
