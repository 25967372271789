import {
  ICardReaderConfiguration,
  ICardReaderDeviceInterface,
  InitializeInterfaceMethod,
  ISignalParser,
  ShutDownInterfaceMethod,
} from '../../../../../@types/cardReader';
import { SIGNAL_TYPES } from '../../types';

export class HidDeviceInterface implements ICardReaderDeviceInterface {
  constructor(signalParser: ISignalParser) {
    this.signalParser = signalParser;
  }
  isReading = false;
  private inputBuffer = '';
  private signalParser: ISignalParser;

  handleInput = (keyPressed: string) => {
    if (keyPressed === 'Enter') {
      console.log(this.inputBuffer);

      this.signalParser.parseSignal(this.inputBuffer);

      this.inputBuffer = '';
    } else {
      this.inputBuffer += keyPressed;
    }
  };

  handleKeypress = (e: KeyboardEvent) => {
    this.handleInput(e.key);
    e.preventDefault();
  };

  initialize: InitializeInterfaceMethod = async () => {
    if (this.isReading) return;

    this.isReading = true;

    // subscribe to keyboard input
    document.addEventListener('keypress', this.handleKeypress);

    this.signalParser.parseSignal(SIGNAL_TYPES.DEVICE_CONNECTED);
  };

  shutDown: ShutDownInterfaceMethod = async () => {
    this.isReading = false;
    document.removeEventListener('keypress', this.handleKeypress);
    this.signalParser.parseSignal(SIGNAL_TYPES.DEVICE_DISCONNECTED);
  };

  configuration: ICardReaderConfiguration = 'No configuration needed';
  needsConfiguration = async () => Promise.resolve(false);
  configure = async () => null;
}
