import { SerialPort } from '../../../../@types/serial';

export const getSerialPortForDeviceId = async (deviceId: string): Promise<SerialPort | null> => {
  console.log('getSerialPortForDeviceId: deviceId: ', deviceId);

  if (!deviceId) return null;

  // gets all serial ports authorized.. should only be the beetek, but check deviceId anyway
  const ports = await navigator?.serial?.getPorts();
  const port = ports?.find((p) => p.getInfo().usbProductId === deviceId) ?? null;
  console.log('getSerialPortForDeviceId: port: ', JSON.stringify(port?.getInfo()));

  return port;
};
