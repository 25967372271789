import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
import { AVALIABLE_ROLES, useUserRoles } from 'guards/RoleBasedGuard';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  group: getIcon('ic_group'),
  competitions: getIcon('ic_competitions'),
  cart: getIcon('ic_cart'),
  maintenance: getIcon('ic_maintenance'),
  createMaintenance: getIcon('ic_create_maintenance'),
  schedules: getIcon('ic_calendar'),
  laptop: getIcon('ic_laptop'),
  customerService: getIcon('ic_support_agent'),
  money: getIcon('ic_money'),
  gear: getIcon('ic_gear'),
  cards: getIcon('ic_cards'),
  diamond: getIcon('ic_diamond'),
};

type SideBarConfigItem = {
  subheader: string;
  items: { title: string; path: string; icon: JSX.Element }[];
};

let adminSideBar: SideBarConfigItem[] = [
  {
    subheader: 'competition management',
    items: [
      {
        title: 'competition group',
        path: PATH_DASHBOARD.groups.list,
        icon: ICONS.group,
      },
      {
        title: 'competition templates',
        path: PATH_DASHBOARD.templates.list,
        icon: ICONS.competitions,
      },
    ],
  },
  {
    subheader: 'B2B management',
    items: [
      {
        title: 'marketplace list',
        path: PATH_DASHBOARD.marketplace.list,
        icon: ICONS.cart,
      },
      {
        title: 'Direct Bets Settings',
        path: PATH_DASHBOARD.marketplace.directBetsFSettings,
        icon: ICONS.money,
      },
    ],
  },
  {
    subheader: 'maintenance management',
    items: [
      {
        title: 'Maintenance Events List',
        path: PATH_DASHBOARD.maintenance.list,
        icon: ICONS.maintenance,
      },
      {
        title: 'Create Maintenance Event',
        path: PATH_DASHBOARD.maintenance.newMaintenance,
        icon: ICONS.createMaintenance,
      },
      {
        title: 'White Diamonds Giveaway',
        path: PATH_DASHBOARD.maintenance.whiteDiamondsGiveaway,
        icon: ICONS.diamond,
      }
    ],
  },
];

let shoeTechnicianSideBar: SideBarConfigItem[] = [
  {
    subheader: 'game management',
    items: [
      {
        title: 'Card Reader Settings',
        path: PATH_DASHBOARD.gameManagement.cardReaderSettings,
        icon: ICONS.gear,
      },
      {
        title: 'Croupier Service',
        path: PATH_DASHBOARD.gameManagement.root,
        icon: ICONS.laptop,
      },
      {
        title: 'Live Dealer Helper',
        path: PATH_DASHBOARD.gameManagement.liveDealerHelper,
        icon: ICONS.cards,
      },
    ],
  },
];

const customerServiceSideBar: SideBarConfigItem[] = [
  {
    subheader: 'customer service',
    items: [
      {
        title: 'Customer Service',
        path: PATH_DASHBOARD.customerService.root,
        icon: ICONS.customerService,
      },
    ],
  },
];

const scheduleSidebar: SideBarConfigItem[] = [
  {
    subheader: 'scheduling events',
    items: [
      {
        title: 'Schedule templates',
        path: PATH_DASHBOARD.schedules.list,
        icon: ICONS.schedules,
      },
      {
        title: 'Scheduler',
        path: PATH_DASHBOARD.schedules.scheduler,
        icon: ICONS.schedules,
      },
    ],
  },
];

const useSidebarConfig = (enableSchedules: boolean) => {
  const { hasAnyOfRoles } = useUserRoles();

  let sidebarContent: SideBarConfigItem[] = [];

  if (hasAnyOfRoles([AVALIABLE_ROLES.CUSTOMER_SERVICE, AVALIABLE_ROLES.BO_ADMINS])) {
    sidebarContent = [...customerServiceSideBar];
  }

  if (hasAnyOfRoles([AVALIABLE_ROLES.BO_ADMINS])) {
    sidebarContent = [...sidebarContent, ...adminSideBar];
    if (enableSchedules) sidebarContent = [...sidebarContent, ...scheduleSidebar];
  }

  if (hasAnyOfRoles([AVALIABLE_ROLES.SHOE_TECHNICIANS])) {
    sidebarContent = [...sidebarContent, ...shoeTechnicianSideBar];
  }

  return sidebarContent;
};

export default useSidebarConfig;
