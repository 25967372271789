import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

interface Props {
  children: React.ReactElement;
}

const FeatureFlagsProvider = ({ children }: Props): ReactElement | null => {
  const [isLoaded, setIsLoaded] = useState(false);

  const growthBookGlobal = useMemo(() => {
    const apiHost = window.BACK_OFFICE_CLIENT_ENV.GROWTHBOOK_API_HOST;
    const clientKey = window.BACK_OFFICE_CLIENT_ENV.GROWTHBOOK_GLOBAL_CLIENT_KEY;
    return new GrowthBook({ apiHost, clientKey });
  }, []);

  const growthBook = useMemo(() => {
    const apiHost = window.BACK_OFFICE_CLIENT_ENV.GROWTHBOOK_API_HOST;
    const clientKey = window.BACK_OFFICE_CLIENT_ENV.GROWTHBOOK_GLOBAL_CLIENT_KEY;
    return new GrowthBook({ apiHost, clientKey });
  }, []);

  useEffect(() => {
    try {
      growthBookGlobal.loadFeatures();
      growthBook.loadFeatures();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoaded(true);
      window.GROWTHBOOK = growthBookGlobal;
    }
  }, [growthBook, growthBookGlobal]);

  if (!isLoaded) {
    return null;
  }

  return <GrowthBookProvider growthbook={growthBook}>{children}</GrowthBookProvider>;
};

export default FeatureFlagsProvider;
