import { CUSTOMER_SERVICE_RECORD_TYPE } from '../../@types/customerServiceSearch';
import {
  CompetitionSearchType,
  CompetitionType,
} from '../../@types/competition';
import {
  maintenanceStatuses,
  marketplaceCountries,
  marketplaceGames,
  marketplaceLanguages,
  marketplaceRegions,
  marketplaceStatuses,
} from './select-values';


export const CUSTOMER_SERVICE_RECORD_TYPE_FILTER = Object.values(CUSTOMER_SERVICE_RECORD_TYPE);

export const COMPETITION_STATUS_FILTER = [
  'Scheduled',
  'Published',
  'Canceled',
  'Finished',
];
export const COMPETITION_SEARCH_TYPE_FILTER: {
  label: string;
  value: CompetitionSearchType;
}[] = [
  { label: 'RMG', value: 'RMG' },
  { label: 'Social', value: 'social' },
];

export const COMPETITION_TYPE_FILTER: {
  label: string;
  value: CompetitionType;
}[] = [{ label: '1-Hand', value: 'ONEHAND' }];

export const GROUP_GAME_FILTER = ['Sportsbook', 'Avantage Baccarat'];
export const MARKETPLACE_FILTERS = [
  {
    name: 'marketplaceStatus',
    label: 'Status',
    values: marketplaceStatuses,
  },
  {
    name: 'languages',
    label: 'Languages',
    values: marketplaceLanguages,
  },
  {
    name: 'games',
    label: 'Games',
    values: marketplaceGames,
  },
  {
    name: 'regions',
    label: 'Regions',
    values: marketplaceRegions,
  },
  {
    name: 'countries',
    label: 'Countries *Multiple',
    values: marketplaceCountries,
  },
];

export const MAINTENANCE_FILTERS = [
  {
    name: 'maintenanceTaskStatus',
    label: 'Status',
    values: maintenanceStatuses,
  },
  {
    name: 'games',
    label: 'Games',
    values: marketplaceGames,
  },
  {
    name: 'marketplaces',
    label: 'B2B Partner(s)',
    values: [{ value: '', name: '' }],
  },
  {
    name: 'startDate',
    label: 'Start Date',
    values: [{ value: '', name: '' }],
  },
  {
    name: 'endDate',
    label: 'End Date',
    values: [{ value: '', name: '' }],
  },
];

export const FILTERS_INITIAL_VALUES = {
  marketplace: {
    marketplaceStatus: [],
    countries: [],
    languages: [],
    games: [],
    regions: [],
  },
  group: {
    game: [],
    status: [],
  },
  competition: {
    competitionSearchType: [],
    competitionType: [],
    status: [],
  },
  maintenance: {
    startDate: [{ from: '', to: '' }],
    endDate: [{ from: '', to: '' }],
    marketplaces: [],
    games: [],
    maintenanceTaskStatus: [],
  },
  customerService: {
    recordType: [],
  }
};
