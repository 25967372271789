import { Paper, PaperProps, Typography } from "@mui/material";

interface SearchNotFoundProps extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({
  searchQuery = "",
  ...rest
}: SearchNotFoundProps) {
  return (
    <Paper {...rest}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not Found
      </Typography>
      <Typography variant="body2" align="center">
        No results found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or
        using complete words
      </Typography>
    </Paper>
  );
}
