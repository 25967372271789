import { Currency } from '../../@types/currency';
import axios from 'utils/axios';
import { AxiosResponse } from 'axios';
import countries from 'utils/constants/countries.json';

export const prizePoolTypes = ['Guaranteed', 'Rolling', 'Shootout'];
export const games = [{ name: 'Avantage Baccarat' }];

export const getCurrencies = async (): Promise<Currency[]> => {
  const response: AxiosResponse<{ data: Currency[] }> = await axios.get(
    `${window.BACK_OFFICE_CLIENT_ENV.API_BASE_URL}/back-office/currencies`
  );

  return response.data.data;
};

export const marketplaceStatuses = [
  { value: 'ACTIVE', name: 'Active' },
  { value: 'INACTIVE', name: 'Inactive' },
  { value: 'PREPARED', name: 'Prepared' },
];

export const marketplaceGames = [
  { value: 'Avantage Baccarat', name: 'Avantage Baccarat' },
  { value: 'Sportsbook', name: 'Sportsbook' },
];
export const marketplaceLanguages = [
  { value: 'English', name: 'English' },
  { value: 'Mandarin', name: 'Mandarin' },
];
export const marketplaceRegions = [
  { value: 'Unregulated', name: 'Unregulated (.com)' },
  { value: 'United Kingdom', name: 'United Kingdom (.uk)' },
];
export const marketplceDisplayTermsAndConditions = [
  { value: 'Yes', name: 'Yes' },
  { value: 'No', name: 'No' },
];
export const marketplaceCountries = countries.map((country) => ({
  value: country.iso,
  name: `${country.NAME} ${country.iso}`,
}));

export const maintenanceStatuses = [
  { value: 'STARTED', name: 'Started' },
  { value: 'SCHEDULED', name: 'Scheduled' },
  { value: 'PUBLISHED', name: 'Published' },
  { value: 'FINISHED', name: 'Finished' },
  { value: 'CANCELED', name: 'Canceled' },
];

export const marketplaceCurrencies = [{ name: 'USD', value: 'USD' }];
