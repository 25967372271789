import React, { FC, useEffect } from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

interface WeeklyRuleSetupProps {
  daysOfWeek: string | null;
  updateField: (field: string, value: string) => void;
  disabled: boolean;
}


const WeeklyRuleSetup: FC<WeeklyRuleSetupProps> = ({ daysOfWeek, updateField, disabled }) => {

  const DAYS_OF_WEEK = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const [selectedDays, setSelectedDays] = React.useState<string[]>(daysOfWeek?.split(',') ?? []);
  const updateSelectedDays = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (selectedDays.includes(value) && !checked) {
      setSelectedDays(selectedDays.filter((day) => day !== value));
    } else if (!selectedDays.includes(value) && checked) {
      setSelectedDays([...selectedDays, value]);
    }

  }
  useEffect(() => {
    if (selectedDays.length !== 0) {
      updateField('daysOfWeek', selectedDays.join(','));
    }

  }, [selectedDays, updateField]);

  return (
    <FormGroup>
      {DAYS_OF_WEEK.map((day, index) => (
        <FormControlLabel
          disabled={disabled}
          key={index}
          name={`daysOfWeek[${day}]`}
          control={
            <Checkbox
              value={day}
              checked={selectedDays.includes(day)}
              onChange={(e) => updateSelectedDays(e)}
            />
          }
          label={day}
        />
      ))}
    </FormGroup>
  );
};

export default WeeklyRuleSetup