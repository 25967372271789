import React, { FC, ReactElement } from "react";
import { filter } from "lodash";
import { Chip, Stack } from "@mui/material";
import { MAINTENANCE_FILTERS } from "utils/constants/filters";
import { LabelStyle, WrapperStyle } from "../styles/styles";
import {
  MaintenanceFilter,
  FormikPropsMaintenanceView,
} from "../../../../@types/maintenance";
import { fDateTime } from "utils/formatTime";

interface MaintenanceEventsOverviewProps {
  formik: FormikPropsMaintenanceView;
  filters: MaintenanceFilter;
  marketplacesData: { name: string; value: string }[];
}

const MaintenanceEventsOverview: FC<MaintenanceEventsOverviewProps> = ({
  formik,
  filters,
  marketplacesData,
}): ReactElement => {
  const { handleSubmit, setFieldValue } = formik;

  const handleRemoveStatus = (filterName: string, value: string) => {
    const newValue = filter(
      filters[`${filterName}` as keyof MaintenanceFilter],
      (filterValue) => filterValue !== value
    );
    handleSubmit();
    if (filterName !== "startDate" && filterName !== "endDate") {
      setFieldValue(filterName, newValue);
    } else {
      if (value.startsWith("From")) {
        setFieldValue(`${filterName}[0].from`, "");
      } else {
        setFieldValue(`${filterName}[0].to`, "");
      }
    }
  };

  const getFilters = (filter: {
    name: string;
    label: string;
    values: {
      value: string;
      name: string;
    }[];
  }) => {
    const key = `${filter.name}` as keyof MaintenanceFilter;
    let filterValues: string[] = [];
    if (key !== "startDate" && key !== "endDate") {
      if (key !== "marketplaces") {
        filterValues = filters[key];
      } else {
        filters[key].forEach((selectedValue) => {
          const marketplaceName = marketplacesData.find(
            (data) => data.value === selectedValue
          )?.name as string;
          filterValues = [...filterValues, marketplaceName];
        });
      }
    } else {
      if (filters[key][0].from) {
        filterValues = [`From: ${fDateTime(filters[key][0].from)}`];
      }
      if (filters[key][0].to) {
        filterValues = [
          ...filterValues,
          `To: ${fDateTime(filters[key][0].to)}`,
        ];
      }
    }
    return filterValues;
  };

  return (
    <>
      {MAINTENANCE_FILTERS.map((filter) => {
        let filterValues = getFilters(filter);
        if (filterValues.length) {
          return (
            <WrapperStyle key={filter.name}>
              <LabelStyle>{filter.label}:</LabelStyle>
              <Stack direction="row" flexWrap="nowrap" sx={{ p: 0.75 }}>
                {filterValues.map((value) => (
                  <Chip
                    key={JSON.stringify(value)}
                    label={value}
                    size="small"
                    onDelete={() => handleRemoveStatus(filter.name, value)}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Stack>
            </WrapperStyle>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default MaintenanceEventsOverview;
