import { CardReaderType, ICardReader } from '../../../../@types/cardReader';
import { HandleSignal } from '../types';
import { BeetekShoeCardReader } from './BeetekShoeCardReader';
import { ComDeviceInterface } from './deviceInterfaces/ComDeviceInterface';
import { HidDeviceInterface } from './deviceInterfaces/HidDeviceInterface';
import { EmulatorCardReader } from './EmulatorCardReader';
import { OpticonCardReader } from './OpticonCardReader';
import { BeetekShoeParser } from './parsers/BeetekShoeParser';
import { RegexParser } from './parsers/RegexParser';
import { UndefinedCardReader } from './UndefinedCardReader';

export const loadCardReader = (
  cardReaderType: CardReaderType,
  handleSignal: HandleSignal
): ICardReader => {
  console.log('loadCardReader: loading ', cardReaderType);

  switch (cardReaderType) {
    case CardReaderType.BEETEK_SHOE:
      return new BeetekShoeCardReader(new ComDeviceInterface(new BeetekShoeParser(handleSignal)));
    case CardReaderType.OPTICON_HID:
      return new OpticonCardReader(new HidDeviceInterface(new RegexParser(handleSignal)));
    case CardReaderType.EMULATOR:
      return new EmulatorCardReader();
    default:
      return new UndefinedCardReader();
  }
};
