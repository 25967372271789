const SEQUENCE_NUMBER_LS_KEY = 'sequenceNumber';

export const getSequenceNumber = () => _sequenceNumber;

export const getInitialSequenceNumber = () => {
  const sequenceNumberFromLS = localStorage.getItem(SEQUENCE_NUMBER_LS_KEY);
  if (sequenceNumberFromLS) {
    return JSON.parse(sequenceNumberFromLS);
  }

  return 0;
}

export const increaseSequenceNumber = () => {
  _sequenceNumber += 1;
  localStorage.setItem(SEQUENCE_NUMBER_LS_KEY, JSON.stringify(_sequenceNumber));
}

export const clearSequenceNumber = () => {
  _sequenceNumber = 0;
  localStorage.removeItem(SEQUENCE_NUMBER_LS_KEY);
}

let _sequenceNumber = getInitialSequenceNumber();