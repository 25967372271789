import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, Table, Container, TableContainer, SelectChangeEvent } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import ListHead from 'components/_dashboard/list/ListHead';
import ListToolbar from 'components/_dashboard/list/ListToolbar';
//import axios from '../../../utils/axios';
import { CUSTOMER_SERVICE_SEARCH_TABLE_HEAD } from 'utils/constants/table-headings';
import TableFooter from 'components/_dashboard/list/TableFooter';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { initialPagination } from 'utils/constants/pages';
import { calculatePages } from 'utils/pages';
import { createRequestQuery } from 'utils/helperFiles';
import useDebounce from 'hooks/useDebounce';
import Filters from 'components/_dashboard/filters/Filters';
import { FilterType } from '../../../@types/filters';
import TableWrapper from 'components/_dashboard/table/TableWrapper';
import {
  CustomerServiceSearchFilter,
  CustomerServiceSearchResult,
  CustomerServiceSearchSortByQuery,
} from '../../../@types/customerServiceSearch';
import CustomerServiceSearchResultsTable from './CustomerServiceSearchResultsTable';
import { Sorting } from '../../../@types/sorting';

const fetchData = ({
  filters,
  limit,
  page,
  sorting,
  debouncedSearchTerm,
}: {
  filters: CustomerServiceSearchFilter;
  limit: number;
  page: number;
  sorting: Sorting;
  debouncedSearchTerm: string;
}): CustomerServiceSearchResult[] => {
  const filtersQuery = createRequestQuery(
    {
      ...filters,
      recordType: filters.recordType.length > 1 ? [] : filters.recordType,
      limit,
      page,
      search: debouncedSearchTerm,
    },
    'filters'
  );

  const sortQuery = createRequestQuery(
    {
      [`${sorting.orderBy}` as keyof CustomerServiceSearchSortByQuery]: [`${sorting.order}`],
    },
    'sortBy'
  );

  const requestQuery = (filtersQuery + sortQuery).slice(1);
  console.log('requestQuery = ', JSON.stringify(requestQuery));

  // const competitionData = await axios.get(    `${window.BACK_OFFICE_CLIENT_ENV.API_BASE_URL}/back-office/competitions/by-group/${groupId}?${requestQuery}`  );
  return [];
};

export default function CustomerServiceSearch() {
  const [searchParams] = useSearchParams();
  const { themeStretch } = useSettings();
  const [pagination, setPagination] = useState(initialPagination);
  const [searchQuery, setSearchQuery] = useState(searchParams.get('searchFor') ?? '');
  const [filters, setFilters] = useState({
    recordType: [] as string[],
    recordId: [] as string[],
    summary: [] as string[],
  });
  const isMountedRef = useIsMountedRef();
  const [searchResults, setSearchResults] = useState([] as CustomerServiceSearchResult[]);
  const debouncedSearchTerm = useDebounce(searchQuery.trim(), 700);
  const isDefaultFilter = Object.values(filters).every((filter) => !filter.length);
  const [sorting, setSorting] = useState({
    order: 'ascending' as 'ascending' | 'descending',
    orderBy: 'recordType' as keyof CustomerServiceSearchSortByQuery,
  });

  useEffect(() => {
    if (isMountedRef.current) {
      (async () => {
        try {
          setPagination((prev) => ({ ...prev, loading: true }));
          const data = fetchData({
            filters,
            limit: pagination.limit,
            page: pagination.page,
            sorting,
            debouncedSearchTerm,
          });
          setSearchResults(data);
          setPagination({ ...initialPagination, loading: false });
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [isMountedRef, pagination.limit, pagination.page, debouncedSearchTerm, filters, sorting]);

  const handleRequestSort = (property: string) => {
    const isAsc = sorting.orderBy === property && sorting.order === 'ascending';
    setSorting({
      order: isAsc ? 'descending' : 'ascending',
      orderBy: property as keyof CustomerServiceSearchSortByQuery,
    });
  };

  const handleSearchQuery = (searchQuery: string) => {
    setPagination({ ...pagination, page: 1 });
    setSearchQuery(searchQuery);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const rows = event.target.value as number;
    const pageCount = calculatePages(rows, pagination);
    setPagination({ ...pagination, page: pageCount, limit: rows });
  };

  return (
    <Page title="Customer Service: Search">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Customer Service"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Customer Service', href: PATH_DASHBOARD.customerService.root },
          ]}
        />

        <Card>
          <ListToolbar
            searchQuery={searchQuery}
            onSearchQuery={handleSearchQuery}
            placeholder="Search for competitions/participants, players, or wagers..."
          >
            <Filters
              filters={filters}
              handleFilters={setFilters as Dispatch<SetStateAction<FilterType>>}
              setPagination={setPagination}
              filterType={'customerService'}
            />
          </ListToolbar>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={sorting.order}
                  orderBy={sorting.orderBy}
                  headLabel={CUSTOMER_SERVICE_SEARCH_TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableWrapper
                  loading={pagination.loading}
                  searchQuery={searchQuery}
                  listLength={!!searchResults?.length}
                  isDefaultFilter={isDefaultFilter}
                  colSpan={12}
                >
                  <CustomerServiceSearchResultsTable
                    filteredCustomerServiceSearchResults={searchResults}
                  />
                </TableWrapper>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TableFooter
            pagination={pagination}
            handleRows={handleChangeRowsPerPage}
            setPagination={setPagination}
          />
        </Card>
      </Container>
    </Page>
  );
}
