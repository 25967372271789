import {
  CardReaderType,
  CardReaderUI,
  ICardReader,
  OnInitialize,
  OnShutdown,
} from '../../../../@types/cardReader';
import CardReaderEmulatorUI from './CardReaderEmulatorUI';

export class EmulatorCardReader implements ICardReader {
  initialize = async (onInitialize: OnInitialize) => {
    onInitialize();
  };
  shutDown = async (isClosing: boolean, onShutdown: OnShutdown) => {
    if (!isClosing) onShutdown();
  };
  cardReaderType = CardReaderType.EMULATOR;
  needsConfiguration = async () => Promise.resolve(false);
  configure = async () => Promise.resolve(null);
  uiComponent: CardReaderUI = CardReaderEmulatorUI;
}
