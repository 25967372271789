import axios from "axios";
import { refreshSession } from "./cognito";
import { tokenExpiration } from "./constants/localStorage";
import { handleInactivity } from "./handleInactivity";
import { setSession } from "./jwt";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    handleInactivity();
    const tokenExp = localStorage.getItem(tokenExpiration) as string;
    if (+tokenExp < +new Date()) {
      const idToken = await refreshSession();
      config.headers.common.Authorization = `Bearer ${idToken}`;
      setSession(idToken);
    }
    return config;
  },
  (error) => console.error(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
