import { SuitType, RankType } from './mappingTypes';

export const SUIT_VALUES: { [key: number]: SuitType } = {
  1: { shoeCode: '1', gssCode: 'S', label: 'Spades' },
  2: { shoeCode: '2', gssCode: 'H', label: 'Hearts' },
  3: { shoeCode: '3', gssCode: 'C', label: 'Clubs' },
  4: { shoeCode: '4', gssCode: 'D', label: 'Diamonds' },
};

export const RANK_VALUES: { [key: string]: RankType } = {
  '01': { shoeCode: '01', gssCode: '1', label: 'Ace' },
  '02': { shoeCode: '02', gssCode: '2', label: '2' },
  '03': { shoeCode: '03', gssCode: '3', label: '3' },
  '04': { shoeCode: '04', gssCode: '4', label: '4' },
  '05': { shoeCode: '05', gssCode: '5', label: '5' },
  '06': { shoeCode: '06', gssCode: '6', label: '6' },
  '07': { shoeCode: '07', gssCode: '7', label: '7' },
  '08': { shoeCode: '08', gssCode: '8', label: '8' },
  '09': { shoeCode: '09', gssCode: '9', label: '9' },
  '10': { shoeCode: '10', gssCode: 'T', label: '10' },
  '11': { shoeCode: '11', gssCode: 'J', label: 'Jack' },
  '12': { shoeCode: '12', gssCode: 'Q', label: 'Queen' },
  '13': { shoeCode: '13', gssCode: 'K', label: 'King' },
};
